import React from 'react';
import classnames from 'classnames';
import { useWindowWidth } from '@react-hook/window-size/throttled';

import Icon from 'components/ui/Icon';
import ArticleCardItem from 'components/partials/ArticleCardItem';
import StepItem from './StepItem';

const Steps = ({ data }) => {
  const { guideStepsBlockArticlesTitle, guideStepsBlockArticlesList } = data;
  const windowWidth = useWindowWidth({ initialWidth: 1024 });

  return (
    <section className="mt-20 sm:mt-40 mb-20 sm:mb-32 lg:mb-40 overflow-hidden">
      <div className="fluid-container">
        <h2 className="flex justify-start items-center text-14 mb-8 sm:mb-16">
          <span>{guideStepsBlockArticlesTitle}</span>

          <Icon
            name="star"
            style={{ '--iconColor': '#FFB44B' }}
            className="mr-auto ml-4 w-14 h-14"
          />
        </h2>

        <ul>
          {guideStepsBlockArticlesList.map((step, index) => {
            const cardData = {
              title: step.guideStepsBlockArticlesListTitle,
              excerpt: step.guideStepsBlockArticlesListDescription,
              uri: step.uri,
            };

            if (!step.guideStepsBlockArticlesListArticle) return null;

            return (
              <li
                className={classnames(
                  'lg:border-b lg:border-mine-shaft -mt-px lg:mt-0',
                  index === 0 ? 'lg:border-t' : '',
                )}
                key={step.guideStepsBlockArticlesListArticle.id}
              >
                {windowWidth > 1024 ? (
                  <StepItem data={step} index={index + 1} />
                ) : (
                  <ArticleCardItem
                    {...cardData}
                    index={index + 1}
                    className="border border-mercury lg:border-none"
                    mod="step"
                  />
                )}
              </li>
            );
          })}
        </ul>
      </div>
    </section>
  );
};

export default Steps;
