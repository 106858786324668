import * as React from 'react';
import classnames from 'classnames';

import { Button } from 'components/ui/Button';

import './styles.scss';

const NotFoundPage = () => {
  return (
    <main className={classnames('overflow-hidden')}>
      <section className="oc-404-section bg-merino text-center h-screen flex justify-center items-center pt-20 lg:pt-32">
        <div className="fluid-container">
          <h1 className="oc-404-title text-pomegranate mb-8">404</h1>

          <p className="oc-h3 mb-16">Sorry, page not found.</p>

          <Button href="/" className="btn btn--fill btn--pomegranate">
            go back to home
          </Button>
        </div>
      </section>
    </main>
  );
};

export default NotFoundPage;
