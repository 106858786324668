import React from 'react';

import Hero from './Hero';
import SiteSections from './SiteSections';
import Meet from './Meet';
// import NonCommerce from 'components/partials/NonCommerce';

// import { gradient } from './styles.module.scss';

const MainPage = ({
  template: {
    mainPage: {
      mainPageHero,
      mainPageSiteSections,
      mainPageMeetUs,
      mainPageFooter,
    },
  },
}) => {
  return (
    <div className="">
      <Hero data={mainPageHero} />
      <SiteSections data={mainPageSiteSections} />
      <Meet data={mainPageMeetUs} />

      {/* <div className={gradient}>
        <NonCommerce bgColor="bg-aqua-haze" />
      </div> */}
    </div>
  );
};

export default MainPage;
