import React, { useRef, useEffect } from 'react';
import { useInViewport } from 'react-in-viewport';

import useJellyEffect from './useJellyEffect';

import imageFront from 'images/main/hero/01.jpg';
import imageBack from 'images/main/hero/02.jpg';
import imageGlass from 'images/main/hero/Glass.png';

const AnimateImage = () => {
  const refImage = useRef(null);
  const refCanvas = useRef(null);
  const refImageGlass = useRef(null);

  const { inViewport } = useInViewport(refCanvas);

  const { start, stop, init } = useJellyEffect({
    canvas: refCanvas,
    image: refImage,
    glass: refImageGlass,
  });

  useEffect(() => {
    if (!refCanvas.current) return;

    const promises = [imageFront, imageBack, imageGlass].map(
      (item) =>
        new Promise((res, rej) => {
          if (!item.complete) {
            const img = new Image();

            img.onload = res;
            img.src = item;
          } else {
            res();
          }
        }),
    );

    Promise.all(promises).then(() => {
      setTimeout(() => {
        init();
        start();
      }, 200);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refCanvas.current]);

  useEffect(() => {
    if (inViewport) {
      start();
    } else {
      stop();
    }
  }, [inViewport, start, stop]);

  return (
    <div className="absolute left-0 top-0 bottom-0 right-0 flex">
      <img
        src={imageFront}
        role="presentation"
        alt=""
        className="w-full h-full object-cover"
      />
      <img
        src={imageBack}
        role="presentation"
        alt=""
        hidden
        ref={refImage}
        className="object-cover"
      />
      <img
        src={imageGlass}
        role="presentation"
        alt=""
        hidden
        ref={refImageGlass}
        className="object-cover"
      />

      <div className="absolute inset-0">
        <canvas
          ref={refCanvas}
          className="block h-full w-full cursor-none"
        ></canvas>
      </div>
    </div>
  );
};

export default AnimateImage;
