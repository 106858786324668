import React from 'react';
import classnames from 'classnames';

import StaticImg from 'components/StaticImg';
import { Button } from 'components/ui/Button';
import { posterWrapper } from './styles.module.scss';
import BreathIn from './BreathIn';
import BreatheOut from './BreatheOut';

import { titleWrap } from './styles.module.scss';

const DesktopState = ({
  breatheTitle = '',
  breatheDescription = '',
  image,
  breatheButton: { url, title },
}) => {
  return (
    <section className="relative">
      <div className="absolute top-0 left-0 right-0 bottom-1/2 bg-merino -z-1"></div>
      <div className="relative fluid-container">
        <div className="flex items-center">
          <div className={classnames(posterWrapper, 'relative flex-none')}>
            <StaticImg path={image} />

            <div className="absolute left-14 xl:left-20 w-4/12 top-20 ">
              <BreathIn />
            </div>

            <div className="absolute left-14 xl:left-20 w-4/12 top-1/2 ">
              <BreatheOut />
            </div>
          </div>
          <div className={classnames('absolute right-0 w-7/12')}>
            <div className="bg-aqua-haze p-16 xl:p-28">
              <div className="max-w-3xl">
                <h2
                  className={classnames('oc-h1 uppercase', titleWrap)}
                  dangerouslySetInnerHTML={{ __html: breatheTitle }}
                ></h2>
                <p className="pt-12 max-w-sm oc-lead-2">{breatheDescription}</p>

                <p className="pt-14">
                  <Button href={url}>{title}</Button>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default DesktopState;
