import React from 'react';
import {
  useWindowWidth,
} from '@react-hook/window-size/throttled';

import DesktopState from './Desktop';
import MobileState from './Mobile';

const image = {
  desktop: 'main/protect-lungs/desktop.jpg',
  mobile: 'main/protect-lungs/mobile.jpg',
};

const ProtectLungs = ({data}) => {
  const windowWidth = useWindowWidth({ initialWidth: 1024 });

  return windowWidth >= 1024 ?
    <DesktopState {...data} image={image.desktop} /> :
    <MobileState {...data} image={image} />
};

export default ProtectLungs;
