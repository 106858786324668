import React from 'react';
import classnames from 'classnames';

import AnimateImage from './AnimateImage';

import { background } from './background.module.scss';

const imageSection = { background: '#341212' };

const Background = () => (
  <div className="absolute inset-0 flex">
    <div className={classnames('bg-merino flex-1', background)}></div>
    <div className="flex-1" style={imageSection}>
      <div className="relative h-full max-h-full">
        <AnimateImage />
      </div>
    </div>
  </div>
);

export default Background;
