class PhysicsMouse {
  constructor(options) {
    const DEFAULT_OPT = {
      maxSpeed: 8,
      spring: 0.05,
      friction: 0.8,
    };

    this.OPTIONS = {
      ...DEFAULT_OPT,
      ...options
    };

    this.vel = {
      x: 0,
      y: 0
    };

    this.pos = {
      x: 0,
      y: 0
    };
  }

  moveCursor(mouse) {
    const dx = mouse.x - this.pos.x;
    const dy = mouse.y - this.pos.y;
    const ax = dx * this.OPTIONS.spring;
    const ay = dy * this.OPTIONS.spring;

    this.vel.x += ax;
    this.vel.y += ay;
    this.vel.x *= this.OPTIONS.friction;
    this.vel.y *= this.OPTIONS.friction;

    this.pos.x += this.vel.x;
    this.pos.y += this.vel.y;

    // смещение под иконку курсора
    const cof = window.innerWidth > 800 ? 45 : 0;

    return {
      x: this.pos.x + cof,
      y: this.pos.y + cof
      // x: this.pos.x,
      // y: this.pos.y
    }
  }

  setPosition(mouse) {
    return this.moveCursor(mouse);
  }
}

export default PhysicsMouse;