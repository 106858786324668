import React from 'react';
import classnames from 'classnames';
import { useWindowWidth } from '@react-hook/window-size/throttled';

import Link from 'components/Link';
import Icon from 'components/ui/Icon';
import Caption from 'components/partials/Caption';
import StaticImg from 'components/StaticImg';

import useOptions from 'hooks/graphql/useOptions';

const images = {
  top: 'risk/smoke.jpg',
  bottom: {
    first: 'risk/img-first.jpg',
    second: 'risk/img-second.jpg',
  },
};

const Article = ({ data }) => {
  const windowWidth = useWindowWidth({ initialWidth: 1024 });
  const options = useOptions();

  const {
    general: {
      risksAndPreventionPage: { risksAndPreventionPageLinkToArticle },
    },
  } = options.general;

  return (
    <section>
      <div className="fluid-container">
        <div className="lg:flex lg:justify-between lg:gap-x-12 2xl:gap-x-28">
          {windowWidth > 1024 ? (
            <div className="flex flex-col max-w-screen-sm">
              <StaticImg
                path={images.top}
                className="-ml-5 sm:-ml-6 lg:-ml-10"
              />

              <div className="my-auto">
                <div className="pl-20">
                  <StaticImg
                    path={images.bottom.first}
                    className=" -mb-20 relative z-1 w-full"
                  />
                </div>

                <StaticImg path={images.bottom.second} className="" />

                <p className="mt-4 uppercase text-aqua-dark oc-small">
                  SOURCE: &copy; TED KINSMAN / SCIENCE PHOTO LIBRARY
                </p>
              </div>
            </div>
          ) : null}

          <article className="lg:flex-2 lg:w-6/12">
            <Caption bgColor="bg-aqua-dark" className="lg:mb-20">
              you can start here
            </Caption>

            {data.map((block, index) => {
              return (
                <>
                  <h2 className="oc-h2 mt-16 mb-5 lg:mt-0 lg:mb-8">
                    {block.title}
                  </h2>

                  <div
                    className="space-y-4 mb-14 lg:mb-20"
                    dangerouslySetInnerHTML={{ __html: block.text }}
                  ></div>

                  {block.imageMobile && windowWidth <= 1024 ? (
                    <StaticImg
                      path={block.imageMobile}
                      className={classnames(
                        ' w-full',
                        index !== 2 ? 'relative -right-5' : '',
                      )}
                    />
                  ) : null}

                  {block.link ? (
                    <Link
                      to={risksAndPreventionPageLinkToArticle?.url}
                      className="oc-lead-1 relative z-1 ml-5 -mr-5  -mt-20 py-10 px-5 lg:mt-16 lg:mx-0 lg:mb-20  lg:py-8 lg:px-10 bg bg-aqua-dark text-aqua-haze flex justify-between items-center uppercase hover:bg-opacity-90"
                    >
                      {risksAndPreventionPageLinkToArticle?.title}

                      <span className="ml-6 w-14 h-14 flex-none flex justify-center items-center border border-white rounded-full">
                        <Icon
                          name="arrow-right"
                          className="w-5 h-5 text-white"
                        />
                      </span>
                    </Link>
                  ) : null}
                </>
              );
            })}
          </article>
        </div>
      </div>
    </section>
  );
};

export default Article;
