import React from 'react';
import classnames from 'classnames';
import { useWindowWidth } from '@react-hook/window-size/throttled';

import StaticImg from 'components/StaticImg';
import WpImage from 'components/WPImage';
import { Button } from 'components/ui/Button';

import BreathIn from './BreathIn';
import BreatheOut from './BreatheOut';

import { imgWrap } from './styles.module.scss';

const MobileState = ({
  title = '',
  description = '',
  image,
  buttonUrl = '#',
  buttonTitle = '',
  breathIn = '',
  breatheOut = '',
  className = '',
  bgButton = '',
  textColor = 'text-white',
}) => {
  const windowWidth = useWindowWidth({ initialWidth: 1024 });

  return (
    <section className={classnames('relative', className)}>
      {/* <WpImage
        className="w-full h-screen"
        src={image?.sourceUrl || ''}
        alt={image?.altText || ''}
      /> */}

      {image?.sourceUrl ? (
        <WpImage
          className="w-full h-screen"
          src={image?.sourceUrl || ''}
          alt={image?.altText || ''}
        />
      ) : (
        <StaticImg
          path={windowWidth > 640 ? image.desktop : image.mobile}
          className={classnames('w-full h-screen', imgWrap)}
        />
      )}

      <div className="absolute inset-0 z-1">
        <div className="fluid-container">
          <div className="relative">
            <div className="pt-8 pb-24 sm:pb-12">
              {breathIn ? <BreathIn data={breathIn} /> : null}
            </div>

            <div className={textColor}>
              <h2 className="oc-h1 uppercase">{title}</h2>
              <p className=" pt-10 sm:max-w-sm oc-lead-2">{description}</p>
            </div>

            <p className="pt-14">
              <Button
                href={buttonUrl}
                className={classnames(
                  'btn--fill btn--white w-full sm:w-auto',
                  bgButton,
                )}
              >
                {buttonTitle}
              </Button>
            </p>

            {breatheOut ? (
              <div className="pt-14">
                <BreatheOut data={breatheOut} />
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </section>
  );
};

export default MobileState;
