import React from 'react';
import { useWindowWidth } from '@react-hook/window-size/throttled';

import { Button } from 'components/ui/Button';
import ArticleCardItem from 'components/partials/ArticleCardItem';

const ArticlesCardTiles = ({ data }) => {
  const {
    guideArticlesCardList,
    guideArticlesCardTitle,
    guideArticlesCardUrl: { url },
  } = data;

  const windowWidth = useWindowWidth({ initialWidth: 1024 });

  if (!guideArticlesCardList) return <></>;

  const articlesToDisplay =
    windowWidth > 1024
      ? guideArticlesCardList?.slice(-6)
      : guideArticlesCardList?.slice(-3);

  return (
    <section className="pt-0 pb-24 sm:pt-0 sm:pb-36 lg:pt-28 lg:-mt-28 bg-merino overflow-hidden">
      <div className="fluid-container">
        <div className="flex justify-between items-center mb-8 sm:mb-16">
          <h2>
            {guideArticlesCardTitle}
            <sup>({guideArticlesCardList.length})</sup>
          </h2>

          {windowWidth > 640 ? (
            <Button
              href={url}
              className="btn--fill btn--dark w-full sm:w-auto uppercase"
            >
              View all
            </Button>
          ) : null}
        </div>

        <ul className="sm:flex sm:flex-wrap sm:-mx-2.5 lg:-mx-5 z-0 relative">
          {articlesToDisplay.slice(-6).map((article, index) => {
            const cardData = {
              title: article.title,
              excerpt: article.excerpt,
              uri: article.uri,
            };

            return (
              <li
                className="pb-5 sm:px-2.5 sm:w-1/2  sm:flex-1 lg:pb-10 lg:flex-3"
                key={article.id}
              >
                <ArticleCardItem {...cardData} index={index + 1} />
              </li>
            );
          })}
        </ul>

        {windowWidth <= 640 ? (
          <Button href={url} className="btn--fill btn--dark w-full mt-10">
            View all
          </Button>
        ) : null}
      </div>
    </section>
  );
};

export default ArticlesCardTiles;
