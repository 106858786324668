import React from 'react';

import Hero from './Hero';
import HowHelp from './HowHelp';
import DontWalkAlone from './DontWalkAlone';
import KnowEnemy from './KnowEnemy';
import ProtectLungs from './ProtectLungs';
// import News from './News';
import Numbers from './Numbers';
import AboutUsPromo from 'components/partials/AboutUsPromo';

const LungCancerPage = ({
  template: {
    lungCancerPage: {
      hero,
      breathe,
      encourage,
      information,
      /*information2,*/ knowing,
      // news,
      numbers,
    },
  },
}) => {
  return (
    <>
      <Hero data={hero} />
      <HowHelp data={information} />
      <DontWalkAlone data={encourage} />
      <KnowEnemy data={knowing} />
      <ProtectLungs data={breathe} />

      {/* TODO: ОТКРЫТЬ КОГДА БУДЕТ СТРАНИЦА НОВОСТЕЙ */}
      {/* <News data={news} /> */}

      {/* TODO: закончить админку для Numbers*/}
      <Numbers data={numbers} />
      <AboutUsPromo bgColor="bg-mischka" />
    </>
  );
};

export default LungCancerPage;
