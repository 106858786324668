import React from 'react';
import { useWindowWidth } from '@react-hook/window-size/throttled';

import StaticImg from 'components/StaticImg';
import Title from './Title';

const image = 'about/no-commerce-banner.jpg';

const NonCommerce = ({ data }) => {
  const windowWidth = useWindowWidth({ initialWidth: 1024 });

  return (
    <section className="pt-20 pb-20 sm:pt-24 lg:pt-28 sm:pb-24 lg:pb-32 bg-rose-light">
      <div className="fluid-container">
        <div className="flex-col-reverse lg:flex-row flex lg:items-stretch 2xl:items-center lg:gap-12 2xl:gap-32">
          <div className="lg:flex-2 pt-10 lg:pt-0 relative z-1">
            {windowWidth >= 1024 ? <Title data={data.title} /> : null}

            <blockquote className="oc-subtitle mb-10 sm:mb-14 lg:mb-16">
              {data.blockquote}
            </blockquote>

            <p className="oc-lead-1 mb-2 font-medium sm:mb-3">
              {data.author.name}
            </p>

            <p className="oc-base text-pomegranate uppercase">
              {data.author.position}
            </p>
          </div>

          <div className="lg:flex-2">
            {windowWidth < 1024 ? <Title data={data.title} /> : null}

            <div className="-mx-5 sm:mx-0">
              <StaticImg path={image} className="w-full" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default NonCommerce;
