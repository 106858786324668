import React from 'react';
import classnames from 'classnames';
import { AnchorLink } from 'gatsby-plugin-anchor-links';

import { useWindowWidth } from '@react-hook/window-size/throttled';

import Icon from 'components/ui/Icon';

const ScrollPointer = ({
  className,
  bgColor = 'bg-pomegranate',
  anchor = '#',
}) => {
  const windowWidth = useWindowWidth({ initialWidth: 640 });

  return (
    <>
      {windowWidth >= 640 ? (
        <AnchorLink
          to={anchor}
          className={classnames(
            'w-24 h-24 text-white flex items-center justify-center rounded-full',
            className,
            bgColor,
            anchor === '#' ? 'pointer-events-none' : '',
          )}
        >
          <Icon name="arrow-bottom" className="h-7 w-7 m-auto animate-bounce" />
        </AnchorLink>
      ) : (
        <AnchorLink
          to={anchor}
          className={classnames(
            'p-5 sm:w-40 sm:h-40 text-white flex items-center justify-center rounded-full sm:flex-col m-auto',
            className,
            bgColor,
          )}
        >
          <span className="ml-4 sm:ml-0 pr-4 sm:pr-0 sm:pb-4 uppercase">
            Learn more
          </span>
          <Icon
            name="arrow-bottom"
            className="h-6 w-6 sm:h-9 sm:w-9 sm:m-auto animate-bounce"
          />
        </AnchorLink>
      )}
    </>
  );
};

export default ScrollPointer;
