import React from 'react';
import classnames from 'classnames';

import Icon from 'components/ui/Icon';

import { section } from './styles.module.scss';

const Working = ({ data }) => {
  return (
    <section
      className={classnames(
        'pb-20 sm:pb-28 lg:pb-40 relative bg-rose-light ',
        section,
      )}
    >
      <div className="fluid-container relative z-1">
        <div className="">
          <h4 className="oc-h4 mb-10 uppercase flex justify-between items-center">
            {data.title}
            <Icon
              name="star"
              className="w-10 h-10"
              style={{ '--iconColor': '#FFB44B' }}
            />
          </h4>

          <ul className="-mx-5 sm:mx-0 bg-white sm:border sm:border-mine-shaft sm:border-b-0">
            {data.list.map((item, index) => (
              <li
                key={item.title}
                className={classnames(
                  index === 2
                    ? 'border-white sm:border-mine-shaft'
                    : 'border-mine-shaft',
                  'py-10 px-5 lg:flex lg:gap-12 2xl:gap-32 items-start sm:p-10 lg:p-20 border-b ',
                )}
              >
                <p className="oc-subtitle uppercase mb-4 mt-4 sm:mt-8 align-bottom sm:mb-4 lg:flex-2 lg:mb-0 inline-block">
                  {item.title}
                </p>

                <p className="oc-lead-1 mt-4 sm:mt-8 lg:mt-0 lg:ml-auto lg:flex-2">
                  {item.text}
                </p>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </section>
  );
};

export default Working;
