import React from 'react';
import TopText from './TopText';

import ScrollPointer from 'components/partials/ScrollPointer';

import image from 'images/main/hero/02.jpg';

const MobileState = ({ data }) => (
  <section className="sm:overflow-hidden border-b border-mine-shaft border-opacity-10 bg-merino">
    <TopText data={data} />

    <div className="pb-14">
      <div className="fluid-container">
        <p className="oc-subtitle">{data.heroSubtitle}</p>
      </div>
    </div>

    <div className="relative">
      <img
        src={image}
        height="475"
        width="100%"
        loading="eager"
        className="h-full w-full"
        alt=""
      />

      <div className="sticky bottom-5">
        <div className="fluid-container -mt-20">
          <ScrollPointer className="" anchor={'/lung-cancer#begin'} />
        </div>
      </div>
    </div>
  </section>
);

export default MobileState;
