import React, { useMemo } from 'react';
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import useStaticImage from 'hooks/graphql/useStaticImage';

const StaticImg = ({ path = null, alt = null, ...props }) => {
  const getStaticImages = useStaticImage();

  const image = useMemo(() => getImage(
    getStaticImages(path)
  ), [getStaticImages, path]);

  if (!path) return null;

  const desc = !alt ? { role: "presentation", alt: '' } : alt;

  return <GatsbyImage
    image={image}
    {...desc}
    {...props}
  />;
};

export default StaticImg;
