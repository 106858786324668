import React, { useMemo } from 'react';
import classnames from 'classnames';
import { useWindowWidth } from '@react-hook/window-size/throttled';

import StaticImg from 'components/StaticImg';
import { Button } from 'components/ui/Button';

import BreathIn from './BreathIn';
import BreatheOut from './BreatheOut';

import { titleWrap } from './styles.module.scss';

const MobileState = ({
  breatheTitle = '',
  breatheDescription = '',
  breatheButton: { url, title },
  image,
}) => {
  const windowWidth = useWindowWidth({ initialWidth: 1024 });

  const pathImage = useMemo(
    () => (windowWidth < 640 ? image.mobile : image.desktop),
    [image.desktop, image.mobile, windowWidth],
  );

  return (
    <section className="relative">
      <StaticImg path={pathImage} className="w-full" />

      <div className="absolute inset-0 z-1">
        <div className="fluid-container">
          <div className="relative">
            <div className="pt-8 pb-24 sm:pb-12">
              <BreathIn />
            </div>

            <h2
              className={classnames('oc-h1 text-white uppercase', titleWrap)}
              dangerouslySetInnerHTML={{ __html: breatheTitle }}
            ></h2>
            <p className="text-white pt-10 sm:max-w-sm oc-lead-2">
              {breatheDescription}
            </p>

            <p className="pt-14">
              <Button
                href={url}
                className="btn--fill btn--white w-full sm:w-auto"
              >
                {title}
              </Button>
            </p>

            <div className="pt-14">
              <BreatheOut />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default MobileState;
