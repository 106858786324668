import React from 'react';

import classnames from 'classnames';

import { section } from './styles.module.scss';

const index = () => {
  return (
    <section
      className={classnames('pb-20 md:pb-40 bg-rose-light overflow-hidden')}
    >
      <div className="fluid-container -mx-5 sm:mx-auto">
        <div
          className={classnames(
            'px-5 relative py-10 md:py-20 h-full flex justify-between items-stretch flex-col-reverse md:flex-row text-white',
            section,
          )}
        >
          <div className="md:h-full flex flex-row-reverse md:flex-col justify-between md:justify-start">
            <p className="uppercase">COMING 2022</p>
            <p className="uppercase md:mt-auto">
              SKIN CANCER
              <br />
              BREAST CANCER
              <br />
              BLOOD CANCER
            </p>
          </div>

          <div className="md:w-1/2 xl:w-1/3 self-center">
            <p className="oc-h1 mb-8">More projects coming soon.</p>
            <p className="oc-lead-2">
              While we’re improving our lung cancer branch, we’re also working
              on expanding the website and adding new conditions. Remember:
              knowledge is one of your best weapons to fight cancer.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default index;
