import React from 'react';
import Icon from 'components/ui/Icon';

const DescriptionText = ({ data }) => {
  return (
    <div className="pt-8 flex text-pomegranate sm:pt-16 lg:pt-0 lg:w-4/12">
      <p className="flex-none mr-3">
        <Icon name="star" className="h-6 w-6" />
      </p>

      <p className="oc-lead-2 ">{data}</p>
    </div>
  );
};

export default DescriptionText;
