import React from 'react';
import classnames from 'classnames';

import StaticImg from 'components/StaticImg';
import { useWindowWidth } from '@react-hook/window-size/throttled';

import ScrollPointer from 'components/partials/ScrollPointer';

import { section, imageWrap } from './styles.module.scss';

const image = {
  desktop: 'about/hero-img.jpg',
  mobile: 'about/hero-img-mob.jpg',
};

const Hero = ({ data }) => {
  const windowWidth = useWindowWidth({ initialWidth: 1024 });

  return (
    <section
      className={classnames(
        'pt-40 bg-rose-light sm:overflow-hidden sm:pt-44 lg:pt-40',
        section,
      )}
    >
      <div className="fluid-container">
        <div className="sm:flex sm:gap-14 sm:items-stretch relative z-1 lg:gap-16 2xl:gap-32">
          <div className="sm:flex-2 sm:pb-8 lg:pb-0 lg:mt-20 lg:mb-0 relative z-1">
            <h1 className="oc-h1 uppercase mb-8 lg:mb-10 text-center sm:text-left">
              {data.title}
            </h1>

            <p className="oc-lead-1 mb-10 sm:mb-14">{data.description}</p>

            {windowWidth > 640 ? (
              <ScrollPointer
                className="sm:mt-14 lg:mr-auto"
                anchor="/about-us#anchor-target"
              />
            ) : null}
          </div>

          <div
            className={classnames('flex-2 -mx-5 sm:mx-0 sm:mt-0', imageWrap)}
          >
            <StaticImg
              path={windowWidth > 640 ? image.desktop : image.mobile}
              className="w-full h-full"
            />

            {windowWidth <= 640 ? (
              <div className="sticky bottom-5 px-5">
                <ScrollPointer
                  className="-mt-20 sm:mt-14 mx-auto"
                  anchor="/about-us#anchor-target"
                />
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
