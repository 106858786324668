import React from 'react';

import StaticImg from 'components/StaticImg';
import Link from 'components/Link';
import Icon from 'components/ui/Icon';

const Mobile = ({
  encourageTitle = '',
  encourageButton: { title = '', url = '#' },
  image,
}) => (
  <section className="relative">
    <div className="absolute inset-0 -z-1">
      <StaticImg path={image} className="h-full w-full" />
    </div>

    <div className="text-white pt-12">
      <div className="fluid-container">
        <div className="flex flex-col">
          <h2 className="oc-h1 uppercase max-w-md text-center sm:text-left">
            {encourageTitle}
          </h2>
        </div>
      </div>

      <div className="md:flex md:items-end mt-80">
        <Link
          href={url}
          className="btn btn--fill btn--rounded-none w-full h-20"
        >
          <span>{title}</span>

          <Icon name="arrow-right" className="ml-5 w-6 h-6" />
        </Link>
      </div>
    </div>
  </section>
);

export default Mobile;
