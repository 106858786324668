import React from 'react';

import Caption from 'components/partials/Caption';
import { Button } from 'components/ui/Button';

import LinksList from './LinksList';

import { wrapper, area1, area2, area3 } from './styles.module.scss';

const KnowEnemy = ({data}) => {
  const { knowingTitle = '', knowingDescription = '', knowingCaption = '', knowingLinks = [], knowingGuide: { title = '', url = '#' } } = data;

  return (
    <section className="bg-merino pb-20 lg:pb-40 pt-20 lg:pt-0">
      <div className="fluid-container">
        <div className={wrapper}>
          <div className={area1}>
            <div className="lg:max-w-md xl:max-w-lg">
              <Caption>{knowingCaption}</Caption>
              <h2 className="oc-h1 pt-14 sm:pt-24">{knowingTitle}</h2>
              <p className="pt-8 sm:pt-10 pb-14 lg:pb-0 oc-lead-2">{knowingDescription}</p>
            </div>
          </div>

          <div className={area3}>
            <LinksList links={knowingLinks} />
          </div>

          <div className={area2}>
            <p className="pt-10 sm:pt-16">
              <Button
                href={url}
                className={'btn--fill w-full sm:w-56'}>{title}
              </Button>
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default KnowEnemy;
