import React from 'react';

const BreathIn = ({ data }) => (
  <p className="flex items-center z-1">
    <span className="block flex-1 h-px bg-white bg-opacity-20 mr-5"></span>
    <span className="text-xs text-white uppercase">{data}</span>
  </p>
);

export default BreathIn;
