/* eslint-disable react-hooks/exhaustive-deps */
import {
  useCallback,
  useMemo
} from 'react';

import useMouse from '@react-hook/mouse-position';

import PhysicsMouse from 'helpers/PhysicsMouse';

const physicsMouse = new PhysicsMouse({
  maxSpeed: 1,
  spring: 0.05,
  friction: 0.7,
});

let isEnable = false;

const useCanvas = ({
  canvas: canvasRef,
  image,
  glass
}) => {
  const RADIUS = 85;

  const mouse = useMouse(canvasRef, {
    enterDelay: 100,
    leaveDelay: 100,
  });

  const canvasState = useMemo(() =>
    canvasRef.current && canvasRef.current.getContext('2d'),
    [canvasRef.current]
  );

  const canvas = useMemo(() => canvasRef.current, [canvasRef.current]);

  const init = useCallback(() => {
    if (!canvas) return;

    canvas.height = canvas.offsetHeight;
    canvas.width = canvas.offsetWidth;
  }, [canvas]);

  const redrawCanvas = useCallback(() => {
    if (!image.current || !glass.current || !canvas) return;

    const _image = image.current;
    const _glass = glass.current;

    const scale = _image.naturalWidth / canvas.width;

    canvasState.clearRect(0, 0, canvas.width, canvas.height);

    const physicsPosition = physicsMouse.moveCursor(mouse);

    const mousePosition = {
      x: RADIUS / scale,
      y: canvas.height - RADIUS / scale
    };

    if (mouse.x && mouse.y) {
      mousePosition.x = physicsPosition.x;
      mousePosition.y = physicsPosition.y;
    }

    if (mouse.x && mouse.y) {
      canvasState.beginPath();
      canvasState.arc(physicsPosition.x, physicsPosition.y, RADIUS / scale, 0, 2 * Math.PI, true);
      canvasState.fill();
    }

    canvasState.globalCompositeOperation = "source-in";

    canvasState.drawImage(
      _image, 0, 0,
      canvas.width,
      canvas.height
    );

    canvasState.globalCompositeOperation = 'source-over';

    canvasState.drawImage(
      _glass,
      mousePosition.x - 1.2 * RADIUS / scale,
      mousePosition.y - 1.2 * RADIUS / scale,
      _glass.naturalWidth / scale,
      _glass.naturalHeight / scale
    );
  }, [RADIUS, canvas, canvasState, glass, image, mouse]);

  const render = useCallback(() => {
    if (!isEnable) return;

    redrawCanvas();
    requestAnimationFrame(render);
  }, [redrawCanvas]);

  const start = useCallback(() => {
    isEnable = true;
    render();
  }, [render]);

  // useEffect(() => {
  //   window.addEventListener('resize', () => { init(); redrawCanvas(); }, false);

  //   return window.removeEventListener('resize', redrawCanvas);
  // }, []);

  const stop = () => isEnable = false;

  return {
    init,
    start,
    stop
  }
};

export default useCanvas;
