const {
  commonPageData
} = require('../commonData.js');

const {
  lungCancerPage
} = require('./lungCancer.js');

const {
  guidePage
} = require('./guide');

const {
  familyPage
} = require('./family');

const {
  mainPage
} = require('./home');

// TODO: Подставить приведенные функции по мере добавлени полей на страницы
//    ... on ${WpTag}Template_Request {
//     ${request(WpTag)}
//   }

const page = (WpTag = '') => `
  ${commonPageData(WpTag)}

  template {
    ... on ${WpTag}Template_LungCancer {
      ${lungCancerPage(WpTag)}
    }

    ... on ${WpTag}Template_Guide {
      ${guidePage(WpTag)}
    }

    ... on ${WpTag}Template_FamilyAndCaregivers {
      ${familyPage(WpTag)}
    }

    ... on ${WpTag}Template_Home {
      ${mainPage(WpTag)}
    }
  }
`;

module.exports.page = page;
