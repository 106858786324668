import React from 'react';
import classnames from 'classnames';

import StaticImg from 'components/StaticImg';
import Icon from 'components/ui/Icon';

import { elips, elipsList } from './styles.module.scss';

const image = 'family/words-circle-img.png';

const ListWords = ({ data }) => {
  return (
    <div className="relative pb-20">
      <ul
        className={classnames('flex flex-col lg:block items-center', elipsList)}
      >
        {data.map((item) => (
          <li
            key={item}
            className={classnames(
              'oc-lead-2 w-56 h-28 sm:w-80 sm:h-40 flex justify-center items-center text-center p-4 bg-white rounded-full',
              elips,
            )}
          >
            <span className="w-3/4">{item}</span>
          </li>
        ))}
      </ul>

      <div className="absolute left-1/2 lg:left-72 -bottom-5 sm:bottom-0 z-1 transform translate-x-0 sm:translate-x-20 lg:translate-x-0">
        <StaticImg path={image} className="w-40 lg:w-56 " />
      </div>

      <Icon
        name="star"
        style={{ '--iconColor': '#519BDC' }}
        className="sm:ml-2.5 w-10 h-10 sm:w-14 sm:h-14 absolute bottom-60 left-0 sm:left-1/2 lg:left-0 transform sm:-translate-x-52 lg:-translate-x-1/2"
      />
    </div>
  );
};

export default ListWords;
