import { useCallback } from 'react';
import { useStaticQuery, graphql } from 'gatsby';

const useStaticImage = () => {
  const {
    allFile: { nodes },
  } = useStaticQuery(graphql`
    query getCloudLogosImages {
      allFile {
        nodes {
          relativePath
          childImageSharp {
            gatsbyImageData(
              placeholder: BLURRED
              quality: 100
              webpOptions: {quality: 100}
              jpgOptions: {progressive: true, quality: 100}
              avifOptions: {quality: 100}
              pngOptions: {quality: 100}
            )
          }
        }
      }
    }
  `);

  return useCallback(
    (path) => nodes.find(({ relativePath }) => relativePath === path),
    [nodes]
  );
}

export default useStaticImage;
