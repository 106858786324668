import React from 'react';
import classnames from 'classnames';

import Icon from 'components/ui/Icon';
import ScrollPointer from 'components/partials/ScrollPointer';

import { section } from './styles.module.scss';

const Hero = ({ data }) => {
  const { guideHeroTitle, guideHeroSubtitle } = data;

  return (
    <section
      className={classnames(
        'relative pt-40 pb-16 bg-merino text-center overflow-hidden sm:pt-52 lg:pt-60',
        section,
      )}
    >
      <div className="fluid-container">
        <div className="mx-auto max-w-screen-md relative z-1">
          <h1 className="oc-h1 mb-7 uppercase">{guideHeroTitle}</h1>

          <p className="mb-16  oc-lead-2 mx-auto w-10/12 sm:mb-0">
            {guideHeroSubtitle}
          </p>

          <div className="mx-auto top-0 w-24 z-1 sm:absolute sm:block sm:mx-0 sm:-right-16 lg:-right-28">
            <Icon
              name="star"
              style={{ '--iconColor': '#FFB44B' }}
              className="w-14 h-14"
            />

            <Icon
              name="star"
              style={{ '--iconColor': '#FFB44B' }}
              className="ml-auto w-10 h-10"
            />
          </div>
        </div>

        <ScrollPointer
          className="mt-16 sm:mt-14 mx-auto"
          anchor="/lung-cancer/guide#anchor-target"
        />
      </div>
    </section>
  );
};

export default Hero;
