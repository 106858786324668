import React from 'react';
import classnames from 'classnames';
import { useWindowWidth } from '@react-hook/window-size/throttled';

import ScrollPointer from 'components/partials/ScrollPointer';

import { section } from './styles.module.scss';

const Hero = ({ data }) => {
  const { risksHeroTitle, risksHeroSubtitle } = data;
  const windowWidth = useWindowWidth({ initialWidth: 640 });

  return (
    <section
      className={classnames(
        'relative pt-40 pb-10 bg-aqua-haze text-center overflow-hidden sm:pb-32 lg:pb-44 sm:pt-52 lg:pt-60 sm:-mb-20 lg:-mb-32',
        section,
      )}
    >
      <div className="fluid-container">
        <div className="mx-auto max-w-screen-md relative z-1">
          <h1 className="oc-h1 mb-7 uppercase">{risksHeroTitle}</h1>

          <p className="sm:mb-16  oc-lead-2 mx-auto sm:w-10/12 ">
            {risksHeroSubtitle}
          </p>
        </div>

        {windowWidth > 640 ? (
          <ScrollPointer
            className="mt-16 sm:mt-14 mx-auto"
            anchor="/lung-cancer/risks-and-prevention#anchor-target"
          />
        ) : null}
      </div>
    </section>
  );
};

export default Hero;
