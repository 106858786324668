import React from 'react';
import classnames from 'classnames';

import Link from 'components/Link';

import { link, readMore } from './styles.module.scss';

const LinksList = ({ links }) => {
  return (
    <ul className="divide-y divide-mine-shaft border-b border-t border-mine-shaft">
      {links.map(
        ({ knowingItem: { url }, knowingItem: { title }, knowingItemSup }) => (
          <li key={title} className="py-6 sm:py-10">
            <Link
              href={url.trim()}
              className={classnames(
                'flex justify-between items-center uppercase hover:text-pomegranate',
                link,
              )}
            >
              <span className="oc-subtitle">
                {title}
                {/* <sup>({knowingItemSup})</sup> */}
              </span>

              <span className={classnames('oc-small', readMore)}>Read now</span>
            </Link>
          </li>
        ),
      )}
    </ul>
  );
};

export default LinksList;
