import React from 'react';

import Hero from './Hero';
import Remember from './Remember';
import MustRead from './MustRead';
import BanneredSec from 'components/partials/BanneredSec';
import Steps from './Steps';
import Words from './Words';
import AboutUsPromo from 'components/partials/AboutUsPromo';

const data = {
  hero: {
    title: 'help and care for your loved one',
    description:
      "If you're helping your family member or friend through cancer treatment, you are a caregiver. This may mean helping with daily activities such as going to the doctor or making meals. It could also mean coordinating services and care, or giving emotional and spiritual support.",
  },
  remember: {
    caption: 'remember',
    text: 'In your new role as a caregiver, your help is critical as your loved one receives cancer therapy. It can be brutal and <span>they will need your help</span> more than ever.',
  },
  banner: {
    image: {
      desktop: 'family/family-article-banner.jpg',
      mobile: 'family/family-article-banner-mob.jpg',
    },
  },
  words: {
    article: [
      {
        title: '<h2>Choose Your Words</h2>',
        text: `
        <p>
          A cancer diagnosis might cause feelings of despair, anger, perplexity, and helplessness. It is beneficial for the individual who has been diagnosed with cancer when friends and family members give a reassuring presence as well as practical assistance. It is frequently difficult for others to know what to say or how to begin a conversation with a cancer patient. However, being in touch is always preferable to being apart.
        </p>
        
        <p>
          Make a point of acknowledging how hard this experience is for the individual. Selecting your words carefully might help you convey your support without being dismissive or ignoring the subject.
        </p>
      `,
      },
      {
        title:
          '<h3>Show your support <span>without</span> using words or choose them wisely.</h3> ',
        text: ``,
        link: {
          title: 'Learn how to protect yourself from radon exposure',
          url: '#',
        },
      },
    ],
    wordslist: [
      'I’m thinking about you.',
      'I’m really sorry for what has accured to you.',
      'If you’re ever want to chat, I’m here to listen.',
      'I’m concerned about you.',
    ],
  },
};

const FamilyPage = ({
  template: {
    familyAndCaregivers: {
      familyArticleOnBanner,
      familyMustReadArticles,
      familySteps,
      familyTalkingToACancerPatient,
    },
  },
}) => {
  const bannerData = {
    title: familyArticleOnBanner.familyArticleOnBannerTitle,
    description: familyArticleOnBanner.familyArticleOnBannerSubtitle,
    image: data.banner.image,
    buttonTitle: familyArticleOnBanner.familyArticleOnBannerButtonText,
    buttonUrl: familyArticleOnBanner?.familyArticleOnBannerArticle?.uri,
    breatheOut: familyArticleOnBanner.familyArticleOnBannerTextOnImage,
  };

  const stepsData = {
    title: familySteps.familyStepsTitle,
    list: familySteps.familyStepsList,
  };

  return (
    <>
      <Hero data={data.hero} />
      <Remember data={data.remember} />
      <MustRead data={familyMustReadArticles} />
      <BanneredSec
        data={bannerData}
        className="bg-aqua-light"
        bgCard="bg-white"
        bgButton="btn--fill btn--sky"
        textColor="text-mine-shaft"
      />
      <Steps data={stepsData} />
      <Words data={data.words} />
      <AboutUsPromo bgColor="bg-white" />
    </>
  );
};

export default FamilyPage;
