import React from 'react';
import classnames from 'classnames';
import { useWindowWidth } from '@react-hook/window-size/throttled';

import StaticImg from 'components/StaticImg';
import Caption from 'components/partials/Caption';

import { titleWrap, descriptionWrap } from './styles.module.scss';

const image = {
  banner: 'about/meet.jpg',
  circle: 'about/meet-circle.png',
};

const Meet = ({ data }) => {
  const windowWidth = useWindowWidth({ initialWidth: 640 });

  return (
    <section className="pt-20 sm:pt-24 lg:pt-40 pb-20 lg:pb-40 bg-white relative lg:-mt-12  2xl:-mt-32">
      <div className="fluid-container" id="anchor-target">
        <Caption bgColor="bg-lilac" className="">
          {data.caption}
        </Caption>

        <div className="flex-col-reverse lg:flex-row flex lg:items-stretch pt-12 lg:pt-24 2xl:pt-40 lg:gap-12 2xl:gap-32">
          <div className="lg:flex-2">
            <div className="-mr-5 -ml-5 lg:mr-0 sm:-ml-6 lg:-ml-10">
              <StaticImg path={image.banner} className="w-full" />
            </div>
          </div>

          <div className="pb-20 lg:pb-0 lg:flex-2 flex flex-col relative">
            <h2
              className={classnames(
                ' mb-8 sm:w-3/4 lg:w-full',
                titleWrap,
                windowWidth > 640 ? 'oc-h2' : 'oc-h1',
              )}
              dangerouslySetInnerHTML={{ __html: data.title }}
            ></h2>

            <p
              className="oc-h4 2xl:w-1/2 mt-auto"
              dangerouslySetInnerHTML={{ __html: data.subtitle }}
            ></p>

            <div className="absolute right-0 -bottom-10 2xl:-bottom-20 ">
              <StaticImg path={image.circle} className="w-28 2xl:w-48 h-auto" />
            </div>
          </div>
        </div>

        <p
          className={classnames(
            'oc-lead-2 mt-8 sm:mt-14 sm:w-3/4 lg:w-1/2',
            descriptionWrap,
          )}
        >
          {data.description}
        </p>
      </div>
    </section>
  );
};

export default Meet;
