import MainPage from 'templates/MainPage';
import LungCancer from 'templates/LungCancer';
import Guide from 'templates/Guide';
import Family from 'templates/Family';
import Risks from 'templates/Risks';
import Terms from 'templates/Terms';
import About from 'templates/AboutUs';

const templatesList = {
  // TODO: page.template.templateName('Family And Caregivers'): Imported template
  Home: MainPage,
  'Lung Cancer': LungCancer,
  Guide,
  'Family And Caregivers': Family,
  'Risks And Prevention': Risks,
  Terms,
  'About Us': About,
};

export default templatesList;
