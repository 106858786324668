import React from 'react';
import WpImage from "components/WPImage";
import { ButtonSmall } from 'components/ui/Button';

const ItemCard = ({ data, index = 0 }) =>
  <div className="relative border border-mercury">
    <div className="sm:flex sm:flex-col items-center w-64 sm:w-80 m-auto pt-14 pb-16">
      <p className="absolute top-10 left-8 sm:left-10 text-pomegranate text-sm sm:text-xs">{
        ('0' + index).slice(-2)
      }</p>

      <div className="m-auto h-40 w-40 sm:h-48 sm:w-48 rounded-full overflow-hidden">
        {
          data.informationListImage ? (
            <WpImage
              className="object-cover w-full h-full"
              src={data.informationListImage?.sourceUrl}
              alt={data.informationListImage?.altText || ''}
            />
          ) : null
        }
      </div>

      <h3 className="oc-subtitle text-center pt-14 sm:pt-14">{data.informationListTitle}</h3>

      <p className="pt-8 sm:pt-14 mt-auto">
        <ButtonSmall
          href={data.informationListButton.url}
          className="w-full sm:w-56"
        >{data.informationListButton.title}</ButtonSmall>
      </p>
    </div>
  </div>;

export default ItemCard;
