import React from 'react';
import classnames from 'classnames';
import { useWindowWidth } from '@react-hook/window-size/throttled';

import { titleWrap } from './styles.module.scss';

const Title = ({ data }) => {
  const windowWidth = useWindowWidth({ initialWidth: 640 });

  return (
    <h2
      className={classnames(
        'mb-10 uppercase sm:mb-14 lg:mb-20',
        titleWrap,
        windowWidth > 640 ? 'oc-h2' : 'oc-h1',
      )}
      dangerouslySetInnerHTML={{ __html: data }}
    ></h2>
  );
};

export default Title;
