import React from 'react';
import classnames from 'classnames';

import Link from 'components/Link';
import Favicon from 'assets/logos/favicon.inline.svg';

import { section } from './styles.module.scss';

const Hero = ({ data }) => {
  const { mainPageMadeBy, mainPageTitle, mainPageWelcome, mainPageCompany } =
    data;

  return (
    <section
      className={classnames(
        'pt-32 sm:pt-32 lg:pt-40 pb-16 sm:pb-32 lg:pb-64 text-center relative overflow-hidden bg-aqua-haze',
        section,
      )}
    >
      <div className="fluid-container">
        <p className="mb-8 lg:mb-14">
          {mainPageMadeBy}&#160;
          <Link
            target="_blank"
            href={mainPageCompany.url}
            className="text-pomegranate hover:opacity-70"
          >
            {mainPageCompany.title}
          </Link>
        </p>

        <p className="oc-lead-1 uppercase mb-6 lg:mb-8">{mainPageWelcome}</p>

        <span className="inline-block mb-12 lg:mb-20">
          <Favicon />
        </span>

        <h1 className="oc-h1 relative">{mainPageTitle}</h1>
      </div>
    </section>
  );
};

export default Hero;
