import React from 'react';
import LeftSide from './Left';
import RightSide from './Right';

const image = 'main/numbers/image.jpg';

const Numbers = ({ data }) => {
  const {
    numbersTitle,
    numbersDesciption,
    numbersAdditionRedTitle,
    numbersAdditionText,
    numbersList,
    numbersCaption,
  } = data;

  return (
    <section className="pt-20 pb-20 sm:pt-28 lg:pt-40 sm:pb-28 lg:pb-40">
      <div className="fluid-container">
        <div className="lg:flex">
          <div className="flex-1">
            <LeftSide
              title={numbersTitle}
              text={numbersDesciption}
              image={image}
              caption={numbersCaption}
            />
          </div>

          <div className="flex-1">
            <RightSide
              list={numbersList}
              title={numbersAdditionRedTitle}
              text={numbersAdditionText}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Numbers;
