const guidePage = (WpTag) => `
  guide {
    guideArticlesTextType {
      guideArticlesTextList {
        ...on ${WpTag}GuideArticleText {
          id
          categories {
            nodes {
              uri
              slug
              name
            }
          }
          title
          tags {
            nodes {
              uri
              slug
              name
            }
          }
          excerpt
          slug
          uri
        }
      }
      guideArticlesTextTitle
      guideArticlesTextUrl {
        title
        url
      }
    }
    guideArticlesCardType {
      guideArticlesCardList {
        ...on ${WpTag}GuideArticleCard {
          id
          categories {
            nodes {
              uri
              slug
              name
            }
          }
          excerpt
          title
          slug
          uri
          tags {
            nodes {
              name
              slug
              uri
            }
          }
        }
      }
      guideArticlesCardTitle
      guideArticlesCardUrl {
        title
        url
      }
    }
    guideArticleOnBanner {
      articleOnBannerCurrentArticle {
        ...on ${WpTag}GuideArticleCard {
          id
          slug
          uri
        }
        ...on ${WpTag}GuideArticleText {
          id
          uri
          slug
        }
      }
      articleOnBannerCurrentTitle
      articleOnBannerCurrentSubtitle
      articleOnBannerCurrentButtonText
      articleOnBannerCurrentImage {
        altText
        sourceUrl
        srcSet
      }
      articleOnBannerCurrentTextOnImage
    }
    guideStepsBlock {
      guideStepsBlockArticlesTitle
      guideStepsBlockArticlesList {
        guideStepsBlockArticlesListTitle
        guideStepsBlockArticlesListDescription
        guideStepsBlockArticlesListArticle {
          ...on ${WpTag}GuideArticleText {
            id
            slug
            uri
          }
          ...on ${WpTag}GuideArticleCard {
            id
            uri
            slug
          }
        }
      }
    }
    guideHero {
      guideHeroTitle
      guideHeroSubtitle
    }
    guideLearningBlock {
      guideLearningBlockTitle
      guideLearningBlockSubtitle
    }
    guideCategories {
      category1 {
        title
        target
        url
      }
      category2 {
        target
        title
        url
      }
      category3 {
        target
        title
        url
      }
      category4 {
        target
        title
        url
      }
      category5 {
        target
        title
        url
      }
      category6 {
        target
        title
        url
      }
    }
  }
`;

module.exports.guidePage = guidePage;
