import React from 'react';
import classnames from 'classnames';
import { useWindowWidth } from '@react-hook/window-size/throttled';

import WpImage from 'components/WPImage';
import { Button } from 'components/ui/Button';

const SiteSection = ({ data }) => {
  const windowWidth = useWindowWidth({ initialWidth: 640 });

  return (
    <section
      className={classnames(
        `${windowWidth > 640 ? '-mt-24 relative z-1' : ' '} `,
      )}
    >
      <div className="fluid-container">
        <ul className="bg-white sm:border sm:border-mine-shaft sm:flex">
          {data.mainPageList.map((section, index) => (
            <li
              key={section.mainPageListSectionUrl.title + index}
              className={classnames(
                'px-8 py-14 text-center flex-3 border-l-0',
                index !== 0 && windowWidth > 640
                  ? 'border-l border-mine-shaft'
                  : '',
                windowWidth <= 640
                  ? 'border-l-0 border-b border-bw-mine-shaft'
                  : '',
              )}
            >
              <WpImage
                src={section.mainPageListIcon?.sourceUrl || ''}
                alt={section.mainPageListIcon?.altText || ''}
                className="mb-8 sm:mb-10 w-36 h-36 sm:w-28 sm:h-28 lg:w-36 lg:h-36"
              />

              <p className="oc-subtitle mb-8 sm:mb-10 uppercase">
                {section.mainPageListSectionUrl.title}
              </p>

              {index === 0 ? (
                <Button
                  href={'/lung-cancer'}
                  // href={section.mainPageListSectionUrl.url}
                  className="btn btn--fill btn--dark"
                >
                  visit website
                </Button>
              ) : (
                <p className="oc-base uppercase opacity-40 h-16 flex justify-center items-center">
                  coming soon
                </p>
              )}
            </li>
          ))}
        </ul>
      </div>
    </section>
  );
};

export default SiteSection;
