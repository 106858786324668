import React from 'react';
import { Button } from 'components/ui/Button';

const ButtonComponent = () => {
  return (
    <Button
      target="_blank"
      href={'https://oncologycompass.ch/'}
      className="btn--pomegranate btn--fill w-full lg:w-auto"
    >
      visit website
    </Button>
  );
};

export default ButtonComponent;
