import React from 'react';
import { useWindowWidth } from '@react-hook/window-size/throttled';

import StaticImg from 'components/StaticImg';
import Caption from 'components/partials/Caption';
import ButtonComponent from './Button';
import OCCanonacalLogo from 'assets/logos/oc-canonical.inline.svg';

const image = 'about/promo-web.jpg';

const PromoWeb = ({ data }) => {
  const windowWidth = useWindowWidth({ initialWidth: 640 });

  return (
    <section className="bg-white">
      <div className="fluid-container overflow-hidden">
        <div className="lg:flex lg:gap-12 2xl:gap-32">
          <div className="lg:flex-2 pt-14 sm:pt-32 pb-10 sm:pb-20 lg:pb-28">
            <Caption className="mb-16">{data.caption}</Caption>

            <div className="sm:w-3/4">
              <h2 className="oc-h1 uppercase">{data.title}</h2>

              <p className="pt-6 sm:pt-14 oc-subtitle">{data.description}</p>

              {windowWidth > 1024 ? (
                <p className="pt-16">
                  <ButtonComponent />
                </p>
              ) : null}
            </div>
          </div>
          <div className="relative lg:flex-2 -mx-container lg:mx-0 w-auto lg:w-auto -mr-5 sm:-mr-6 lg:-mr-10 ">
            <StaticImg path={image} className="h-full max-h-full w-full" />

            <i className="absolute top-1/2 left-1/2 w-1/2 h-auto transform -translate-y-1/2 -translate-x-1/2">
              <OCCanonacalLogo className="w-full h-auto" />
            </i>

            {windowWidth <= 1024 ? (
              <p className="absolute right-5 bottom-5 left-5">
                <ButtonComponent />
              </p>
            ) : null}
          </div>
        </div>
      </div>
    </section>
  );
};

export default PromoWeb;
