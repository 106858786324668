import React from 'react';
import classnames from 'classnames';

import { ButtonSmall } from 'components/ui/Button';

import { excerptWrap } from './styles.module.scss';

const ArticleItem = ({ title, uri, excerpt, index, className, mod }) => {
  const numeric = mod ? mod + ' ' + 0 + index : '0' + index;

  return (
    <div
      className={classnames(
        'oc-radial-hover oc-radial-hover--orange p-8 flex flex-col h-full lg:p-10 bg-white',
        mod ? 'pb-14' : '',
        className,
      )}
    >
      {index ? (
        <p className="oc-small mb-12 lg:mb-14 text-pomegranate uppercase">
          {numeric}
        </p>
      ) : null}

      <p className="oc-h4 mb-6 lg:mb-8">{title}</p>

      <div className={excerptWrap}>
        <div
          className={classnames(
            'oc-lead-1 mb-auto text-mine-shaft text-opacity-70',
            // excerptWrap,
          )}
          dangerouslySetInnerHTML={{ __html: excerpt }}
        ></div>
      </div>

      <div className="mt-auto">
        <ButtonSmall href={uri} className="mt-8 w-full sm:w-56 uppercase">
          Learn more
        </ButtonSmall>
      </div>
    </div>
  );
};

export default ArticleItem;
