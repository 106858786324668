import React from 'react';
import classnames from 'classnames';
import { useWindowWidth } from '@react-hook/window-size/throttled';

import Icon from 'components/ui/Icon';
import ScrollPointer from 'components/partials/ScrollPointer';

import { section } from './styles.module.scss';

const Hero = ({ data }) => {
  const windowWidth = useWindowWidth({ initialWidth: 640 });

  return (
    <section
      className={classnames(
        'relative pt-40 pb-10 bg-aqua-light text-center overflow-hidden sm:pb-32 lg:pb-44 sm:pt-52 lg:pt-60 sm:-mb-20 lg:-mb-32',
        section,
      )}
    >
      <div className="fluid-container">
        <div className="relative">
          <div className="mx-auto max-w-screen-md relative z-1">
            <h1 className="oc-h1 mb-7 uppercase">{data.title}</h1>

            <p className="sm:mb-16  oc-lead-2 mx-auto sm:w-10/12 ">
              {data.description}
            </p>
          </div>

          {windowWidth > 640 ? (
            <ScrollPointer
              bgColor="bg-mine-shaft"
              className="mt-16 sm:mt-14 mx-auto"
              anchor="/lung-cancer/family-and-caregivers#anchor-target"
            />
          ) : null}

          {windowWidth > 1024 ? (
            <>
              <Icon
                name="star"
                style={{ '--iconColor': '#519BDC' }}
                className="absolute top-20 right-20 w-12 h-12 opacity-40"
              />

              <Icon
                name="star"
                style={{ '--iconColor': '#519BDC' }}
                className="absolute bottom-10 left-20 w-20 h-20 "
              />
            </>
          ) : null}
        </div>
      </div>
    </section>
  );
};

export default Hero;
