import React from 'react';

import Hero from './Hero';
import Category from './Category';
import Learning from './Learning';
import Steps from './Steps';
import ArticlesCardTiles from './ArticlesCardTiles';
import ArticlesTextSwiper from './ArticlesTextSwiper';
import BanneredSec from 'components/partials/BanneredSec';

import AboutUsPromo from 'components/partials/AboutUsPromo';

const GuidePage = ({
  template: {
    guide: {
      guideHero,
      guideLearningBlock,
      guideStepsBlock,
      guideArticlesTextType,
      guideArticlesCardType,
      guideCategories,
      guideArticleOnBanner: {
        articleOnBannerCurrentTitle,
        articleOnBannerCurrentSubtitle,
        articleOnBannerCurrentImage,
        articleOnBannerCurrentButtonText,
        articleOnBannerCurrentArticle,
        articleOnBannerCurrentTextOnImage,
      },
    },
  },
}) => {
  const bannerData = {
    title: articleOnBannerCurrentTitle,
    description: articleOnBannerCurrentSubtitle,
    image: articleOnBannerCurrentImage,
    buttonTitle: articleOnBannerCurrentButtonText,
    buttonUrl: articleOnBannerCurrentArticle?.uri,
    breatheOut: articleOnBannerCurrentTextOnImage,
  };

  return (
    <>
      <Hero data={guideHero} />
      <Category data={guideCategories} />
      <Learning data={guideLearningBlock} />
      <ArticlesTextSwiper data={guideArticlesTextType} />
      <ArticlesCardTiles data={guideArticlesCardType} />
      <BanneredSec data={bannerData} />
      <Steps data={guideStepsBlock} />
      <AboutUsPromo bgColor="bg-mischka" />
    </>
  );
};

export default GuidePage;
