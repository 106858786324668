const familyPage = (WpTag) => `
  familyAndCaregivers {
    familyArticleOnBanner {
      familyArticleOnBannerTitle
      familyArticleOnBannerTextOnImage
      familyArticleOnBannerSubtitle
      familyArticleOnBannerButtonText
      familyArticleOnBannerArticle {
        ...on ${WpTag}GuideArticleCard {
          id
          slug
          uri
        }
        ...on ${WpTag}GuideArticleText {
          id
          uri
          slug
        }
      }
    }
    familyMustReadArticles {
      familyMustReadArticlesTitle
      familyArticlesList {
        ...on ${WpTag}GuideArticleText {
          id
          title
          tags {
            nodes {
              name
              slug
              uri
            }
          }
          categories {
            nodes {
              uri
              slug
              name
            }
          }
          uri
        }
        ...on ${WpTag}GuideArticleCard {
          id
          title
          uri
          tags {
            nodes {
              name
              uri
              slug
            }
          }
          categories {
            nodes {
              name
              uri
              slug
            }
          }
        }
      }
      familyMustReadArticlesViewAllUrl {
        title
        url
      }
    }
    familySteps {
      familyStepsTitle
      familyStepsList {
        familyStepsListUrl {
          title
          url
        }
        familyStepsListTitle
        familyStepsListDescription
      }
    }
    familyTalkingToACancerPatient {
      familyLinkToPage {
        ...on ${WpTag}GuideArticleCard {
          id
          slug
          uri
        }
        ...on ${WpTag}GuideArticleText {
          id
          uri
          slug
        }
      }
    }
  }
`;

module.exports.familyPage = familyPage;
