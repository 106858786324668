import React from 'react';
import {
  page
} from '../constans/graphql/page/index';
import Loader from 'components/Loader';

import withPreview from '@/hoc/withPreview';
import conf from '../constans/pageTemplate';
import Page404 from 'templates/Page404';

const Page = ({
  pageContext,
  loaded = true
}) => {
  if (!loaded) return <Loader />;

  const {
    page
  } = pageContext;
  const Component = conf[page.template.templateName];

  if (!Component) return <Page404 />;

  return <Component {...page} />;
};

const queryPreview = `
  query PageTemplatePreviewQuery($id: ID = "") {
    page(id: $id, idType: DATABASE_ID, asPreview: true) {
      ${page()}
    }
  }
`;

export default withPreview(Page, {
  queryPreview,
});
