import React from 'react';
import {
  useWindowWidth,
} from '@react-hook/window-size/throttled';

import Desktop from './Desktop';
import Mobile from './Mobile';

const image = {
  desktop: 'main/dont-walk/desktop.jpg',
  mobile: 'main/dont-walk/mobile.jpg',
};

const DontWalkAlone = ({data}) =>
  useWindowWidth({ initialWidth: 1024 }) >= 1024 ?
    <Desktop {...data} image={image.desktop} /> :
    <Mobile {...data} image={image.mobile} />

export default DontWalkAlone;
