import React from 'react';
import { useWindowWidth } from '@react-hook/window-size/throttled';

import { Button } from 'components/ui/Button';
import Swiper from 'components/partials/ArticlesSwiper';

const MustRead = ({ data }) => {
  const {
    familyMustReadArticlesTitle,
    familyMustReadArticlesViewAllUrl: { url },
    familyArticlesList,
  } = data;

  const windowWidth = useWindowWidth({ initialWidth: 640 });

  if (!familyArticlesList) return <></>;

  return (
    <section className="pt-20 pb-24 sm:pt-36 sm:pb-28 lg:pb-40 bg-aqua-light overflow-hidden">
      <div className="fluid-container">
        <div className="flex justify-between items-center mb-8 sm:mb-16">
          <h2 id="anchor-target">
            {familyMustReadArticlesTitle}
            <sup>({familyArticlesList?.length})</sup>
          </h2>

          {windowWidth > 640 ? (
            <Button
              href={url}
              className="btn--fill btn--dark w-full sm:w-auto uppercase"
            >
              View all
            </Button>
          ) : null}
        </div>

        <Swiper
          data={familyArticlesList}
          cardHover="oc-radial-hover oc-radial-hover--aqua-light"
          categoryColor="bg-sky"
          tagColor="border-sky text-sky hover:bg-sky hover:text-white"
        />

        {windowWidth <= 640 ? (
          <Button
            href={url}
            className="mt-14 btn--fill btn--dark w-full uppercase"
          >
            View all
          </Button>
        ) : null}
      </div>
    </section>
  );
};

export default MustRead;
