import React from 'react';
import classnames from 'classnames';

import Icon from 'components/ui/Icon';

import { section, title } from './styles.module.scss';

const FactorsRow = ({ data }) => {
  return (
    <section className={classnames('sm:pb-28 lg:pb-40 relative', section)}>
      <div className="fluid-container relative z-1">
        <div className="">
          <h4 className="oc-h4 mb-10 uppercase flex justify-between items-center">
            {data.title}
            <Icon
              name="star"
              className="w-10 h-10"
              style={{ '--iconColor': '#FFB44B' }}
            />
          </h4>

          <ul className="-mx-5 sm:mx-0 bg-white sm:border sm:border-mine-shaft sm:border-b-0">
            {data.list.map((item, index) => (
              <li
                key={item.title}
                className={classnames(
                  index === 2
                    ? 'border-white sm:border-mine-shaft'
                    : 'border-mine-shaft',
                  'py-10 px-5 lg:flex items-start sm:p-10 lg:p-20 border-b ',
                )}
              >
                <p className="mr-6 w-16 h-16 self-start sm:mr-8 inline-flex lg:w-24 lg:h-24 bg-aqua-haze rounded-full sm:inline-flex justify-center items-center flex-none ">
                  <Icon
                    name={item.icon}
                    className="w-16 h-16 lg:w-24 lg:h-24 "
                  />
                </p>

                <p
                  className={classnames(
                    'oc-subtitle uppercase mb-7 mt-4 sm:mt-4 lg:mt-8 align-bottom sm:mb-6 lg:mb-0 sm:mr-5 inline-block',
                    title,
                  )}
                >
                  {item.title}
                </p>

                <p className="oc-lead-1 mt-4 sm:mt-8 lg:mt-0 lg:ml-auto lg:w-5/12">
                  {item.text}
                </p>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </section>
  );
};

export default FactorsRow;
