import React from 'react';
import classnames from 'classnames';
import { useWindowWidth } from '@react-hook/window-size/throttled';

import StaticImg from 'components/StaticImg';
import WpImage from 'components/WPImage';
import { Button } from 'components/ui/Button';
import { posterWrapper } from './styles.module.scss';
import BreathIn from './BreathIn';
import BreatheOut from './BreatheOut';

const DesktopState = ({
  title = '',
  description = '',
  image,
  buttonUrl = '#',
  buttonTitle = '',
  breathIn = '',
  breatheOut = '',
  className = '',
  bgCard = 'bg-aqua-haze',
  bgButton = '',
  textColor = 'text-mine-shaft',
}) => {
  const windowWidth = useWindowWidth({ initialWidth: 1024 });

  return (
    <section className={classnames('relative', className)}>
      <div className="absolute top-0 left-0 right-0 bottom-1/2 bg-merino -z-1"></div>
      <div className="relative fluid-container overflow-hidden">
        <div className="flex items-center">
          <div className={classnames(posterWrapper, 'relative flex-none')}>
            {image?.sourceUrl ? (
              <WpImage
                src={image?.sourceUrl || ''}
                alt={image?.altText || ''}
              />
            ) : (
              <StaticImg
                path={windowWidth > 640 ? image.desktop : image.mobile}
                className="w-full h-full"
              />
            )}

            {breathIn !== '' ? (
              <div className="absolute left-14 xl:left-20 w-4/12 top-20 ">
                <BreathIn data={breathIn} />
              </div>
            ) : null}

            {breatheOut ? (
              <div className="absolute left-14 xl:left-20 w-4/12 top-1/2 ">
                <BreatheOut data={breatheOut} />
              </div>
            ) : null}
          </div>
          <div className={classnames('absolute right-0 w-7/12', textColor)}>
            <div className={classnames(' p-16 xl:p-28', bgCard)}>
              <div className="max-w-3xl">
                <h2 className="oc-h1 uppercase">{title}</h2>
                <p className="pt-12 max-w-sm oc-lead-2">{description}</p>

                <p className="pt-14">
                  <Button className={bgButton} href={buttonUrl}>
                    {buttonTitle}
                  </Button>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default DesktopState;
