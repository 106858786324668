import React from 'react';

import ScrollPointer from 'components/partials/ScrollPointer';

const TextBlock = ({ data }) => (
  <div className="flex flex-col w-1/2 min-h-screen pt-56">
    <div className="relative m-auto pr-5">
      <h1 className="oc-h1 uppercase">{data.heroTitle}</h1>
    </div>

    <div className="relative pb-20 -ml-5 mt-10 pl-5 z-1">
      <div
        className="absolute right-0 top-0 bottom-0 -z-1"
        style={{ left: '-50vw' }}
      ></div>

      <p className="text-xl max-w-lg">{data.heroSubtitle}</p>

      <ScrollPointer className="mt-20" anchor={'/lung-cancer#begin'} />
    </div>
  </div>
);

export default TextBlock;
