import React from 'react';
import classnames from 'classnames';

import Icon from 'components/ui/Icon';

import { constainer, constainerTitle } from './styles.module.scss';

const Factors = ({ data }) => {
  return (
    <section className="pt-10 pb-12 sm:pt-28 lg:pt-44 sm:pb-28 lg:pb-40">
      <div className="fluid-container">
        <div
          className="sm:border sm:border-mine-shaft sm:flex "
          id="anchor-target"
        >
          {data.map((block) => (
            <div
              key={block.title}
              className={classnames('py-14 sm:p-7 lg:p-20 flex-2', constainer)}
            >
              <h4
                className={classnames('oc-h4 mb-14 uppercase', constainerTitle)}
                dangerouslySetInnerHTML={{ __html: block.title }}
              ></h4>
              <ul className="space-y-8 sm:space-y-10">
                {block.list.map((item) => (
                  <li
                    key={item.title}
                    className="flex items-center sm:block lg:flex lg:items-center"
                  >
                    <p className="w-16 h-16 sm:mb-2.5 lg:w-24 lg:h-24 mr-6 lg:mr-10 lg:mb-0 flex-none bg-white rounded-full flex justify-center items-center">
                      <Icon
                        name={item.icon}
                        className="w-16 h-16 lg:w-24 lg:h-24 "
                      />
                    </p>

                    <p className="oc-subtitle uppercase">{item.title}</p>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Factors;
