import React from 'react';
import classnames from 'classnames';
import { useWindowWidth } from '@react-hook/window-size/throttled';

import StaticImg from 'components/StaticImg';
import Caption from 'components/partials/Caption';

import { titleWrap } from './styles.module.scss';
import { Button } from 'components/ui/Button';

const image = {
  banner: 'about/meet.jpg',
  circle: 'about/meet-circle.png',
};

const Meet = ({ data }) => {
  const {
    mainPageMeetCaption,
    mainPageMeetDescription,
    mainPageMeetTitle,
    mainPageMeetUrl,
  } = data;
  const windowWidth = useWindowWidth({ initialWidth: 640 });

  return (
    <section className="pt-20 sm:pt-24 lg:pt-40 sm:pb-20 lg:pb-40 bg-white relative">
      <div className="fluid-container">
        <div className=" lg:flex-row-reverse lg:flex lg:items-stretch lg:gap-12 2xl:gap-32">
          <div className="pb-10 lg:py-20 lg:flex-2 relative">
            <Caption className="mb-14 sm:mb-20">{mainPageMeetCaption}</Caption>

            <h2
              className={classnames(
                ' mb-8 sm:w-3/4 lg:w-full',
                titleWrap,
                windowWidth > 640 ? 'oc-h2' : 'oc-h1',
              )}
              dangerouslySetInnerHTML={{ __html: mainPageMeetTitle }}
            ></h2>

            <p
              className="oc-lead-2 sm:mb-16"
              dangerouslySetInnerHTML={{ __html: mainPageMeetDescription }}
            ></p>

            {windowWidth > 640 ? (
              <Button
                href={mainPageMeetUrl.url}
                className="btn btn--fill btn--pomegranate"
              >
                {mainPageMeetUrl.title}
              </Button>
            ) : null}
          </div>

          <div className="lg:flex-2 relative sm:static">
            <div className="-mr-5 -ml-5 lg:mr-0 sm:-ml-6 lg:-ml-10 h-full ">
              <StaticImg path={image.banner} className="w-full h-full" />
            </div>

            {windowWidth <= 640 ? (
              <div className="absolute right-5 bottom-5 left-5">
                <Button
                  href={mainPageMeetUrl.url}
                  className="btn btn--fill btn--pomegranate  w-full"
                >
                  {mainPageMeetUrl.title}
                </Button>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Meet;
