import React from 'react';

const BreatheOut = ({ data }) => (
  <p className="flex items-center z-1">
    <span className="block h-3.5 w-3.5 bg-white rounded-full mr-7"></span>
    <span className="text-xs text-white uppercase tracking-3xwidest sm:tracking-2xwidest lg:tracking-3xwidest">
      {data}
    </span>
  </p>
);

export default BreatheOut;
