import React from 'react';

import Hero from './Hero';
import Remember from './Remember';
import Factors from './Factors';
import Article from './Article';
import FactorsRow from './FactorsRow';
import Things from './Things';
import AboutUsPromo from 'components/partials/AboutUsPromo';

const data = {
  hero: {
    risksHeroTitle: 'protect your lungs from cancer',
    risksHeroSubtitle:
      'What is a risk factor? It’s an element that raises the probability of acquiring a disease. Different cancers have varying risk factors. Here you will find information about risk factors for lung cancer and how to prevent yourself from getting it. ',
  },
  remember: {
    caption: 'remember',
    text: 'Having certain risk factors <span>does not guarantee</span> that you will contract the condition. And some individuals who are diagnosed with lung cancer <span>may have no known risk factors.</span>',
  },
  factors: [
    {
      title: 'Risk factors you <u>can</u> change',
      list: [
        {
          icon: 'cigarette',
          title: 'tabacco Smoking',
        },
        {
          icon: 'group-smoking',
          title: 'exposure to secondhand smoke',
        },
        {
          icon: 'dna-connection',
          title: 'Exposure to Carcinogene Agents',
        },
      ],
    },
    {
      title: 'Risk factors you <span>cannot</span> change',
      list: [
        {
          icon: 'radiation',
          title: 'Radiation',
        },
        {
          icon: 'comercial-smoke',
          title: 'Air pollution',
        },
        {
          icon: 'dna',
          title: 'family history and genetics',
        },
      ],
    },
  ],
  factorsRow: {
    title: 'Uncertain risk factors',
    list: [
      {
        icon: 'joint-smoke',
        title: 'marijuana Smoking',
        text: 'Although the evidence is inconclusive, there are reasons to believe that smoking marijuana raises the risk of lung cancer. Its smoke includes tar and many of the same carcinogenic compounds as tobacco smoke. Additionally, because marijuana is deeply inhaled and smoked for a long time, any carcinogenic chemicals have increased access to deposits in the lungs. It is currently unknown whether there is a link between marijuana and lung cancer because it’s not legal yet in many places and information gathering about its use is therefore difficult.',
      },
      {
        icon: 'vape-smoke',
        title: 'E-Cigarettes',
        text: 'E-cigarettes are a form of electronic nicotine delivery system. Although they do not contain tobacco, they are generally considered "tobacco" devices. Because E-cigarettes are new, more research is needed to assess their risk of developing lung cancer. ',
      },
      {
        icon: 'talc',
        title: 'Talc And talcum powder',
        text: 'Talc is a mineral that may contain asbestos in its natural state. Talc miners and workers who operate talc mills have been linked to an increased risk of lung cancer and other respiratory diseases as a result of their exposure to industrial-grade talc, according to some studies. However, other research has not found a link between lung cancer and talc. Cosmetic talcum powder has not been linked to an increased risk of lung cancer. ',
      },
    ],
  },
  things: {
    caption: 'things you’re not responsible for',
    list: [
      {
        title: 'Air Pollution',
        text: 'It appears to raise the risk of lung cancer in urban areas slightly. This risk is far lower than that caused by smoking, yet it has been estimated that outdoor air pollution may account for 5% of all deaths from lung cancer worldwide.',
      },
      {
        title: 'Genetics',
        text: "Brothers, sisters, and other relatives of someone who has had lung cancer may have a slightly greater risk of developing it themselves, especially if the relative was diagnosed at an earlier age. It's not clear how much of this risk might be attributed to family members sharing genes and how much is to shared household hazards.",
      },
      {
        title: 'Radiation',
        text: 'People who have had radiation therapy to the chest for other cancers are more likely to get lung cancer, especially if they smoke.',
      },
    ],
  },
  article: [
    {
      title: 'Smoking',
      text: `
        <p>
          Smoking is by far the most important risk of lung cancer. Smoking is believed to be responsible for around 80% of all lung cancer fatalities, and this proportion is almost certainly higher for small cell lung cancer (SCLC). It's rather unusual for someone who has never smoked to develop SCLC. 
        </p>
        
        <p>
          For individuals who smoke, the risk of lung cancer is considerably greater than that of non-smokers. The longer you smoke and the more packs of cigarettes a day you consume, the higher your risk of developing lung cancer. Almost as likely as cigarette smoking, pipe and cigar smoking are also linked to lung cancer. 
        </p>

        <p>
          Smoking low-tar or “light” cigarettes has the same promoting effect on lung cancer as regular cigarettes. Because menthol aids in deep inhalation, menthol cigarettes may enhance the risk of lung cancer even more.
        </p>
      `,
      imageMobile: 'risk/smoke-mob.jpg',
    },
    {
      title: 'Secondhand Smoke',
      text: `
         <p>
          Breathing in the smoke of others (also called second-hand smoke) has been linked to an increased risk of lung cancer if you don't smoke yourself.
        </p>

        <p>
          <strong>
            Second-hand smoke is considered to cause more than 7,000 lung cancer deaths each year.
          </strong>
        </p>
      `,
    },
    {
      title: 'Radon Exposure',
      text: `
        <p>
          Radon is a colourless, odourless gas that is radioactive. It is created naturally through the breakdown of radioactive components such as uranium, which are present in varying amounts across the world's soil and rock. Radon gas in the soil and rock can enter air, groundwater, and surface water through underground waterways. Radon is found in both outdoor and indoor air. It's most frequently discovered in low levels of air outside and drinking water from rivers and lakes. It can be found at higher concentrations in the air indoors, as well as in well water drawn from underground sources. Radon breaks down into solid radioactive by-products called radon progeny, which can attach to dust and other particles and be breathed into the lungs, giving off radiation that may harm DNA within the body's cells.
        </p>
        <p>
          <strong>
            Most of the people's exposure to radon comes from breathing indoors in homes, offices, schools, and other constructions.
          </strong>
        </p>
      `,
      link: {
        title: 'Learn how to protect yourself from radon exposure',
        url: '#',
      },
      imageMobile: 'risk/img-first-mob.jpg',
    },
    {
      title: 'Asbestos Exposure',
      text: `
        <p>
          More likely to die of lung cancer are those who work with asbestos (such as in mines, mills, textile plants, places where insulation is used, and shipyards). Workers exposed to asbestos who also smoke have a much higher risk of developing lung cancer.  It's unclear how little or brief asbestos exposure might increase lung cancer risk. Those who were exposed to large amounts of asbestos have a higher chance of getting mesothelioma, which begins in the lining surrounding the lungs (the pleura).
        </p>
        
        <p>
          Over the last few years, government laws have considerably decreased the usage of asbestos in commercial and industrial products. It may still be found in many houses and other older structures, although it is no longer considered hazardous if it isn't emitted via deterioration, demolition, or renovation.
        </p>
      `,
      imageMobile: 'risk/img-second-mob.jpg',
    },
    {
      title: 'Other Cancerogenes',
      text: `
        <p>
          Uranium, for example, is a radioactive compound that can cause lung cancer. Inhaled chemicals such as arsenic, beryllium, cadmium, silica, vinyl chloride, nickel compounds, chromium compounds, coal products, mustard gas, and diesel exhausts have all been linked to increased lung cancer risk. 
        </p>
        <p>
          In recent years, the government and businesses have initiated several steps to help safeguard employees from these risks. However, the dangers still exist, so if you work with these chemicals, be cautious about how much time you spend around them.
        </p>
      `,
    },
  ],
};

const Risks = () => {
  return (
    <>
      <Hero data={data.hero} />
      <Remember data={data.remember} />

      <div className="bg-aqua-haze overflow-hidden">
        <Factors data={data.factors} />
        <Article data={data.article} />
        <FactorsRow data={data.factorsRow} />
        <Things data={data.things} />
      </div>

      <AboutUsPromo bgColor="bg-mischka" />
    </>
  );
};

export default Risks;
