import React from 'react';
import { useWindowWidth } from '@react-hook/window-size/throttled';

import Caption from 'components/partials/Caption';
import StaticImg from 'components/StaticImg';

const image = {
  desktop: 'risk/things-bg.jpg',
  mobile: 'risk/things-bg-mob.jpg',
};

const Things = ({ data }) => {
  const windowWidth = useWindowWidth({ initialWidth: 640 });

  return (
    <section className="relative pt-20 sm:pt-5 pb-20 sm:pb-28 2xl:-mt-80 2xl:pt-80 2xl:pb-32">
      <div className="fluid-container">
        <div className="relative z-1">
          <Caption className="mb-16 2xl:mb-20 2xl:ml-auto 2xl:w-5/12">
            {data.caption}
          </Caption>

          <ul className="space-y-14 2xl:space-y-20 lg:ml-auto lg:w-1/2 xl:w-6/12 2xl:w-5/12 xl:pr-24">
            {data.list.map((item) => (
              <li key={item.title}>
                <h2 className="oc-h2 mb-5 2xl:mb-8">{item.title}</h2>

                <p className="oc-lead-1">{item.text}</p>
              </li>
            ))}
          </ul>
        </div>
      </div>

      <div className="absolute top-0 left-0 w-full h-full">
        <StaticImg
          path={windowWidth > 640 ? image.desktop : image.mobile}
          className="w-full h-full"
        />
      </div>
    </section>
  );
};

export default Things;
