import React from 'react';
import Link from 'components/Link';
import Icon from 'components/ui/Icon';

const StepItem = ({ data }) => {
  const {
    familyStepsListTitle = '',
    familyStepsListDescription = '',
    familyStepsListUrl: { url },
  } = data;

  return (
    <div className="flex justify-start items-center py-14">
      <p className="oc-h5 flex-3 uppercase mr-auto">{familyStepsListTitle}</p>

      <p className="oc-lead-1 w-4/12 text-mine-shaft text-opacity-70">
        {familyStepsListDescription}
      </p>

      <Link
        to={url}
        aria-disabled
        className="flex-shrink-0 ml-auto flex justify-center items-center w-20 h-20 rounded-full bg-mine-shaft p-2 text-white"
      >
        <Icon name="arrow-right" className="h-6 w-6" />
      </Link>
    </div>
  );
};

export default StepItem;
