import React from 'react';

// import Caption from 'components/partials/Caption';
// import Icon from 'components/ui/Icon';

const TopText = ({ data }) => (
  <div className="pt-40 sm:pt-44">
    <div className="fluid-container relative">
      {/* <Caption className="pb-14 sm:pb-20">{data.heroCaption}</Caption> */}

      <h1 className="oc-h1 uppercase max-w-xl pb-10 sm:pb-14 text-center sm:text-left">
        {data.heroTitle}
      </h1>
    </div>
  </div>
);

export default TopText;
