import React from 'react';
import {
  useWindowWidth,
} from '@react-hook/window-size/throttled';

import DesktopState from './DesktopState/index';
import MobileState from './MobileState/index';

const Hero = ({data}) => {
  const windowWidth = useWindowWidth({ initialWidth: 1024 });

  return windowWidth > 1024 ?
    <DesktopState data={data} /> :
    <MobileState data={data} />
};

export default Hero;
