import React from 'react';
import classnames from 'classnames';

import { section } from './styles.module.scss';

const Terms = ({ content }) => {
  return (
    <section className={classnames('pt-32 pb-16 lg:pt-40 lg:pb-24', section)}>
      <div className="fluid-container">
        <article
          className="lg:w-1/2 mx-auto"
          dangerouslySetInnerHTML={{ __html: content }}
        ></article>
      </div>
    </section>
  );
};

export default Terms;
