const lungCancerPage = (WpTag) => `
  lungCancerPage {
    breathe {
      breatheTitle
      breatheDescription
      breatheButton {
        url
        title
      }
    }
    encourage {
      encourageTitle
      encourageButton {
        url
        title
      }
    }
    hero {
      heroTitle
      heroSubtitle
      heroCaption
    }
    information {
      fieldGroupName
      informationTitle
      informationDescription
      informationCaption
      informationList {
        informationListTitle
        informationListButton {
          url
          title
        }
        informationListImage {
          altText
          srcSet
          sourceUrl
        }
      }
    }
    information2 {
      information2Title
      information2Description
      information2Button {
        title
        url
      }
    }
    knowing {
      knowingTitle
      knowingDescription
      knowingCaption
      knowingLinks {
        knowingItem {
          url
          title
        }
        knowingItemSup
      }
      knowingGuide {
        url
        title
      }
    }
    news {
      newsCaption
      newsTitle
      newsDescription
      newsAdditionalText
    }
    numbers {
      numbersCaption
      numbersTitle
      numbersDesciption
      numbersAdditionText
      numbersAdditionRedTitle
      numbersList {
        numbersItemValue
        numbersItemTitle
      }
    }
  }
`;

module.exports.lungCancerPage = lungCancerPage;
