import React from 'react';

import Caption from 'components/partials/Caption/index';

import CardsBlock from './CardsBlock';

const HowHelp = ({ data }) => {
  const {
    informationCaption,
    informationTitle,
    informationDescription,
    informationList = [],
  } = data;

  return (
    <section className="pt-20 sm:pt-28 lg:pt-40 pb-20 lg:pb-36">
      <div className="fluid-container">
        <Caption>{informationCaption}</Caption>

        <div
          className="lg:flex lg:justify-between pt-14 sm:pt-16 lg:pt-24"
          id="begin"
        >
          <h2 className="oc-h1 flex-none pr-10">{informationTitle}</h2>
          <p className="sm:w-4/12 pt-6 sm:pt-16 lg:pt-0 oc-lead-2">
            {informationDescription}
          </p>
        </div>

        <div className="pt-14 sm:pt-16">
          <CardsBlock cards={informationList} />
        </div>
      </div>
    </section>
  );
};

export default HowHelp;
