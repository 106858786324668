import React from 'react';
import classnames from 'classnames';
import Caption from 'components/partials/Caption';
import StaticImg from 'components/StaticImg';

import { titleStyle } from './left.module.scss';

const LeftSide = ({ title, text, image, caption }) => {
  return (
    <div className="pb-14 lg:pb-0 lg:max-w-2xl">
      <Caption>{caption}</Caption>

      <div className="sm:flex sm:justify-between lg:block">
        <div className="sm:pr-4">
          <h2
            className={classnames('oc-h1 pt-14 lg:pt-24', titleStyle)}
            dangerouslySetInnerHTML={{ __html: title }}
          ></h2>

          <p className="pt-8 sm:pt-10 lg:max-w-lg oc-lead-2">{text}</p>
        </div>

        <div className="sm:flex-none pt-10 sm:pt-0 lg:pt-24">
          <StaticImg
            path={image}
            className="h-52 w-full sm:rounded-full sm:h-64 sm:w-64 transform translate-x-0"
          />
        </div>
      </div>
    </div>
  );
};

export default LeftSide;
