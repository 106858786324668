import React from 'react';
import classnames from 'classnames';
import { useWindowWidth } from '@react-hook/window-size/throttled';

import StaticImg from 'components/StaticImg';
import { Button } from 'components/ui/Button';
import Caption from 'components/partials/Caption';

import useOptions from 'hooks/graphql/useOptions';

const image = 'common/about-promo.jpg';

const AboutUsPromo = ({ bgColor = 'bg-mercury' }) => {
  const windowWidth = useWindowWidth({ initialWidth: 640 });
  const options = useOptions();

  const {
    aboutUsPromo: {
      aboutUsTitle,
      aboutUsDescription,
      aboutUsCaption,
      aboutUsButton: { title, url },
    },
  } = options.aboutUsPromo;

  return (
    <section className={classnames(bgColor)}>
      <div className="fluid-container">
        <div className="lg:flex">
          <div className={'flex-1 pt-14 sm:pt-32 pb-10 sm:pb-20 lg:pb-28'}>
            <Caption bgColor="bg-lilac" className="mb-16">
              {aboutUsCaption}
            </Caption>

            <div className="lg:pr-20">
              <h2 className="oc-h1 uppercase">{aboutUsTitle}</h2>

              <p className="pt-6 sm:pt-14 oc-subtitle">{aboutUsDescription}</p>

              {windowWidth > 640 ? (
                <p className="pt-16">
                  <Button
                    href={url}
                    className="btn--fill btn--dark w-full sm:w-auto"
                  >
                    {title}
                  </Button>
                </p>
              ) : null}
            </div>
          </div>
          <div className="relative lg:flex-1 -mx-container lg:mx-0 w-auto lg:w-auto lg:static sm:-mr-6 lg:-mr-10">
            <StaticImg path={image} className="h-full max-h-full w-full" />

            {windowWidth <= 640 ? (
              <p className="absolute right-5 bottom-5 left-5">
                <Button
                  href={url}
                  className="btn--fill btn--dark w-full sm:w-auto"
                >
                  {title}
                </Button>
              </p>
            ) : null}
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutUsPromo;
