import React from 'react';
import { useWindowWidth } from '@react-hook/window-size/throttled';

import DesktopState from './Desktop';
import MobileState from './Mobile';

// TODO: эта сукция должна быть сквозная, такая же и на главной, но там пока используется локальный файл

const ProtectLungs = ({ data, className, bgCard, bgButton, textColor }) => {
  const windowWidth = useWindowWidth({ initialWidth: 1024 });

  return windowWidth >= 1024 ? (
    <DesktopState
      {...data}
      className={className}
      bgCard={bgCard}
      bgButton={bgButton}
      textColor={textColor}
    />
  ) : (
    <MobileState
      {...data}
      className={className}
      bgCard={bgCard}
      bgButton={bgButton}
      textColor={textColor}
    />
  );
};

export default ProtectLungs;
