import React from 'react';
import classnames from 'classnames';
import { useWindowWidth } from '@react-hook/window-size/throttled';

import Icon from 'components/ui/Icon';
import ArticleCardItem from 'components/partials/ArticleCardItem';
import StepItem from './StepItem';

import { section } from './styles.module.scss';

const Steps = ({ data }) => {
  const { title, list } = data;
  const windowWidth = useWindowWidth({ initialWidth: 1024 });

  return (
    <section
      className={classnames(
        'pt-20 sm:pt-40 pb-20 sm:pb-32 lg:pb-40 overflow-hidden bg-aqua-light',
        section,
      )}
    >
      <div className="fluid-container">
        <h4 className="flex justify-start items-start sm:items-center text-14 pr-4 mb-8 sm:mb-16 uppercase relative z-1">
          <span>{title}</span>

          <Icon
            name="star"
            // style={{ '--iconColor': '#FFB44B' }}
            className="ml-auto w-10 h-10 text-sky flex-none"
          />
        </h4>

        <ul className="lg:border-r lg:border-l lg:border-mine-shaft relative z-1">
          {list.map((step, index) => {
            const cardData = {
              title: step.familyStepsListTitle,
              excerpt: step.familyStepsListDescription,
              uri: step.familyStepsListUrl.url,
            };

            return (
              <li
                className={classnames(
                  'lg:border-b lg:border-mine-shaft -mt-px lg:mt-0 lg:px-20 bg-white',
                  index === 0 ? 'lg:border-t' : '',
                )}
                key={index}
              >
                {windowWidth > 1024 ? (
                  <StepItem data={step} />
                ) : (
                  <ArticleCardItem
                    {...cardData}
                    // index={index + 1}
                    className="border border-mercury lg:border-none"
                    mod="step"
                  />
                )}
              </li>
            );
          })}
        </ul>
      </div>
    </section>
  );
};

export default Steps;
