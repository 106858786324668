import React from 'react';
import classnames from 'classnames';
import { useWindowWidth } from '@react-hook/window-size/throttled';

import Link from 'components/Link';
import Icon from 'components/ui/Icon';
import Caption from 'components/partials/Caption';

import ListWords from './ListWords';

import useOptions from 'hooks/graphql/useOptions';

import { titleWrap, textWrap } from './styles.module.scss';

const Words = ({ data }) => {
  const windowWidth = useWindowWidth({ initialWidth: 1024 });
  const options = useOptions();

  const {
    general: {
      familyAndCaregiversPage: { familyAndCaregiversPageLinkToArticle },
    },
  } = options.general;

  return (
    <section className="pb-20 sm:pb-32 lg:pb-40 bg-aqua-light">
      <div className="fluid-container">
        <div className="lg:flex lg:justify-between lg:gap-x-12 2xl:gap-x-10">
          {windowWidth > 1024 ? (
            <div className="lg:flex-2">
              <ListWords data={data.wordslist} />
            </div>
          ) : null}

          <article className="lg:flex-2 lg:w-6/12">
            <Caption bgColor="bg-sky" className="lg:mb-20">
              talking to a cancer patient
            </Caption>

            {data.article.map((block, index) => {
              return (
                <>
                  {index === 1 && windowWidth <= 1024 ? (
                    <ListWords data={data.wordslist} />
                  ) : null}
                  <div
                    className={classnames(
                      'mt-14 mb-5 lg:mt-0 lg:mb-8',
                      titleWrap,
                    )}
                    dangerouslySetInnerHTML={{ __html: block.title }}
                  ></div>

                  {block?.text ? (
                    <div
                      className={classnames(
                        'space-y-4 mb-14 lg:mb-20 oc-lead-1',
                        textWrap,
                      )}
                      dangerouslySetInnerHTML={{ __html: block.text }}
                    ></div>
                  ) : null}

                  {block.link ? (
                    <Link
                      to={familyAndCaregiversPageLinkToArticle?.url}
                      className="oc-lead-1 relative z-1 py-10 px-5 mt-10 lg:mt-16 lg:mx-0 lg:py-8 lg:px-10 bg bg-sky text-aqua-haze flex justify-between items-center uppercase hover:bg-opacity-90"
                    >
                      {familyAndCaregiversPageLinkToArticle?.title}

                      <span className="ml-6 w-14 h-14 flex-none flex justify-center items-center border border-white rounded-full">
                        <Icon
                          name="arrow-right"
                          className="w-5 h-5 text-white"
                        />
                      </span>
                    </Link>
                  ) : null}
                </>
              );
            })}
          </article>
        </div>
      </div>
    </section>
  );
};

export default Words;
