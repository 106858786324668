import React from 'react';

import StaticImg from 'components/StaticImg';
// import { Button } from 'components/ui/Button';
import Link from 'components/Link';
import Icon from 'components/ui/Icon';

const DesktopState = ({
  encourageTitle = '',
  encourageButton: { title = '', url = '#' },
  image,
}) => (
  <section className="relative pb-36">
    <div className="fluid-container">
      <div className="relative w-max m-auto max-w-full">
        <StaticImg path={image} />

        <div className="absolute inset-0 flex flex-col text-white lg:p-10 xl:p-16">
          <h2 className="oc-h1 uppercase">{encourageTitle}</h2>

          <div className="absolute right-0 bottom-0 w-1/2">
            <Link
              href={url}
              className="btn btn--fill btn--rounded-none w-full h-28"
            >
              <span>{title}</span>

              <Icon name="arrow-right" className="ml-5 w-6 h-6" />
            </Link>
          </div>
        </div>
      </div>
    </div>
    <div className="absolute bottom-0 top-1/2 left-0 right-0 bg-merino -z-1"></div>
  </section>
);

export default DesktopState;
