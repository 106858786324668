import React from 'react';
import SwiperCore, { Navigation, Scrollbar } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useWindowWidth } from '@react-hook/window-size/throttled';

import Icon from 'components/ui/Icon';
import ArticleCard from 'components/partials/RelatedArticles/components/CardRelatedArticles';

import 'swiper/swiper.scss';
import './styles.scss';

SwiperCore.use([Scrollbar, Navigation]);

const SwiperComponent = ({ data, cardHover, categoryColor, tagColor }) => {
  const windowWidth = useWindowWidth({ initialWidth: 640 });

  return (
    <div className="oc-guide-swiper">
      <Swiper
        className="overflow-visible"
        slidesPerView={1}
        spaceBetween={20}
        direction="horizontal"
        scrollbar={{
          el: '.swiper-scrollbar',
        }}
        navigation={{
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        }}
        breakpoints={{
          640: {
            slidesPerView: 'auto',
            spaceBetween: 20,
          },
        }}
      >
        {data.map(({ id, ...article }, i) => (
          <SwiperSlide key={id + i} className="oc-guide-slide">
            <ArticleCard
              data={article}
              cardHover={cardHover}
              className="oc-radial-hover oc-radial-hover--orange"
              categoryColor={categoryColor}
              tagColor={tagColor}
            />
          </SwiperSlide>
        ))}
      </Swiper>

      <div className="fluid-container oc-controls-wrap -mx-5 sm:mx-0">
        <div className="swiper-scrollbar flex-1"></div>

        {windowWidth > 640 ? (
          <div className="oc-nav-wrap">
            <div className="oc-swiper-nav-btn swiper-button-prev">
              <Icon name="arrow-left" className="w-5 h-5" />
            </div>

            <div className="oc-swiper-nav-btn swiper-button-next">
              <Icon name="arrow-right" className="w-5 h-5" />
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default SwiperComponent;
