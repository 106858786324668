import React from 'react';
import classnames from 'classnames';
import { useWindowWidth } from '@react-hook/window-size/throttled';

import StaticImg from 'components/StaticImg';
import DescriptionText from './DescriptionText';

import { imgWrap } from './styles.module.scss';

const image = {
  desktop: 'guide/guide-learning.jpg',
  mobile: 'main/guide-learning.jpg',
};

const Learning = ({ data }) => {
  const { guideLearningBlockTitle, guideLearningBlockSubtitle } = data;
  const windowWidth = useWindowWidth({ initialWidth: 1024 });

  return (
    <section className="relative mt-20 pb-20 sm:pb-0 lg:mt-32 ">
      <div className="fluid-container">
        <div className="mb-10 lg:mb-20 lg:flex lg:justify-between">
          <h2 className="oc-h1 sm:mb-14 lg:w-5/12 lg:mb-0">
            {guideLearningBlockTitle}
          </h2>

          {windowWidth > 1024 ? (
            <DescriptionText data={guideLearningBlockSubtitle} />
          ) : null}
        </div>

        <div
          className={classnames(
            '-mx-5 h-96 lg:h-auto sm:mx-0 text-center',
            imgWrap,
          )}
        >
          <StaticImg
            path={image.desktop}
            className="object-left"
            objectPosition="center"
          />
        </div>

        {windowWidth <= 1024 ? (
          <DescriptionText data={guideLearningBlockSubtitle} />
        ) : null}
      </div>

      <div className="absolute right-0 bottom-0 left-0 -z-1 w-full h-80 bg-merino sm:h-72 lg:h-36"></div>
    </section>
  );
};

export default Learning;
