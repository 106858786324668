import React from 'react';

import ItemCard from './ItemCard';

const CardsBlock = ({ cards = [] }) =>
  <div className="overflow-auto">
    <ul className="sm:flex sm:w-auto lg:w-full">
      {
        cards.map((item, index) =>
          <li key={index} className="sm:flex-1">
            <ItemCard data={item} index={index + 1} />
          </li>
        )
      }
    </ul>
  </div>;

export default CardsBlock;
