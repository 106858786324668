import React from 'react';

const RightSide = ({ list, title, text }) => {
  return (
    <div>
      <ul>
        {list.map(
          ({ numbersItemTitle: title, numbersItemValue: number }, index) => (
            <li
              key={index}
              className="flex space-x-5 sm:space-x-0 lg:block pt-5 pb-12 sm:pb-14 lg:pb-24 border-t border-mine-shaft"
            >
              <h3 className="w-1/2 lg:w-auto text-sm sm:text-xl uppercase">
                {title}
              </h3>
              <p className="w-1/2 lg:w-auto text-7xl sm:text-9xl lg:pt-8 text-pomegranate text-right lg:text-left">
                {number}
              </p>
            </li>
          ),
        )}
      </ul>

      <div className="max-w-md">
        <h3 className="text-pomegranate oc-subtitle uppercase">{title}</h3>
        <p className="pt-3 sm:pt-5 oc-lead-2">{text}</p>
      </div>
    </div>
  );
};

export default RightSide;
