import React from 'react';

import TextBlock from './TextBlock';
import Background from './Background';

const DesktopState = ({ data }) => (
  <section className="relative overflow-hidden">
    <Background />

    <div className="fluid-container">
      <TextBlock data={data} />
    </div>
  </section>
);

export default DesktopState;
