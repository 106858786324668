import React from 'react';
import classnames from 'classnames';

import Link from 'components/Link';
import Icon from 'components/ui/Icon';

import { tile } from './categoryItem.module.scss';

const CategoryItem = ({ data: { url, title } }) => {

  return (
    <Link
      to={url}
      className={classnames(
        tile,
        'oc-lead-1 py-6 flex justify-between items-center uppercase lg:py-12 lg:px-5 lg:justify-center h-full',
      )}
    >
      {title}

      <Icon
        name="arrow-right"
        className="ml-2.5 w-7 h-7 text-mine-shaft lg:text-white text-opacity-50 "
      />
    </Link>
  );
};

export default CategoryItem;
