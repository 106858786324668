import React from 'react';
import { useWindowWidth } from '@react-hook/window-size/throttled';

import { Button } from 'components/ui/Button';
import Swiper from 'components/partials/ArticlesSwiper';

const ArticlesTextSwiper = ({ data }) => {
  const {
    guideArticlesTextTitle,
    guideArticlesTextUrl: { url },
    guideArticlesTextList,
  } = data;

  const windowWidth = useWindowWidth({ initialWidth: 640 });

  return (
    <section className="pt-0 pb-24 sm:pt-36 sm:pb-28 lg:pb-40 bg-merino overflow-hidden">
      <div className="fluid-container">
        <div className="flex justify-between items-center mb-8 sm:mb-16">
          <h2>
            {guideArticlesTextTitle}
            <sup>({guideArticlesTextList && guideArticlesTextList.length})</sup>
          </h2>

          {windowWidth > 640 ? (
            <Button
              href={url}
              className="btn--fill btn--dark w-full sm:w-auto uppercase"
            >
              View all
            </Button>
          ) : null}
        </div>

        {guideArticlesTextList && <Swiper data={guideArticlesTextList} />}

        {windowWidth <= 640 ? (
          <Button
            href={url}
            className="mt-14 btn--fill btn--dark w-full uppercase"
          >
            View all
          </Button>
        ) : null}
      </div>
    </section>
  );
};

export default ArticlesTextSwiper;
