import React from 'react';
import classnames from 'classnames';

import Caption from 'components/partials/Caption';
import CategoryItem from './CategoryItem';

import { tilesList } from './styles.module.scss';

const Category = ({ data }) => {
  return (
    <section className="mt-20 mb-20 lg:mb-32">
      <div className="fluid-container" id="anchor-target">
        <Caption className="mb-16">welcome to our guide</Caption>

        <ul
          className={classnames(
            tilesList,
            'lg:flex lg:flex-wrap lg:border lg:border-mercury',
          )}
        >
          {Object.values(data)
            .filter(({ url }) => url)
            .map((category, i) => {
              return (
                <li key={i} className={classnames('lg:flex-3')}>
                  <CategoryItem data={category} />
                </li>
              );
            })}
        </ul>
      </div>
    </section>
  );
};

export default Category;
