import React from 'react';

import Hero from './Hero';
import Meet from './Meet';
import NonCommerce from './NonCommerce';
import Working from './Working';
import ProjectsSoon from './ProjectsSoon';
import PromoWeb from './PromoWeb';

const data = {
  hero: {
    title: 'the purpose of our project',
    description:
      'From basic information about lung cancer and its causes to in-depth information on specific lung cancer types – including risk factors, early detection, diagnosis, and treatment options – you’ll find it here.',
  },
  meet: {
    caption: 'meet us',
    description:
      'We’re committed to providing every individual with clear, credible, evidence-based health and wellness information that’s distinguished by its compassion for the human experience.',
    title:
      'We want to assist in this difficult situation on every step of <span>the challenge</span>.',
    subtitle:
      'Before the diagnosis. <br /> During the treatment. <br /> When in recovery. <br /> While in remission.',
  },
  nonCommerce: {
    title: 'non-commercial <span>Privately</span> financed',
    blockquote:
      '“We’ve created the Oncology Compass with the aim of supporting people suffering from lung cancer or facing this disease. Our team members and the mediacal specialists working with us want to offer as much knowledge as possible so that people might get a better understanding of what is necessary and possible to take up this challenge.”',
    author: {
      name: 'Christian Fillinger',
      position: 'Founder & CEO of Capptoo',
    },
  },
  working: {
    title: 'working together',
    list: [
      {
        title: 'a network of Professionals',
        text: 'We are a highly motivated and close-knit team of medical and non-medical professionals who want to assist people facing lung cancer. We are confident that this knowledge and experience can be useful for cancer patients.',
      },
      {
        title: 'a wealth of Information',
        text: 'The Oncology Сompass is a treasure trove of useful information gathered by our lung cancer experts. We are sure that each of you will be able to find something valuable and very useful here for yourself. We want you to know that you are not alone!',
      },
      {
        title: 'a commited team',
        text: 'As a team, we are committed to what we are doing. We find our work very fulfilling and appreciate it if caregivers might find it useful and patients will benefit from it. ',
      },
    ],
  },
  ocWebSite: {
    caption: 'this might be beneficial',
    title: 'Are you a physician?',
    description:
      'The Oncology Compass is an international network that connects cancer specialists with a variety of professionals. They share their experiences and abilities.',
  },
};

const AboutUs = () => {
  return (
    <>
      <Hero data={data.hero} />
      <Meet data={data.meet} />
      <NonCommerce data={data.nonCommerce} />
      <Working data={data.working} />
      <ProjectsSoon />
      <PromoWeb data={data.ocWebSite} />
    </>
  );
};

export default AboutUs;
