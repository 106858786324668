const mainPage = (WpTag) => `
  mainPage {
    mainPageHero {
      mainPageWelcome
      mainPageTitle
      mainPageMadeBy
      mainPageCompany {
        title
        url
      }
    }
    mainPageFooter {
      mainPageFooterCopyright
      mainPageFooterRegistration
    }
    mainPageMeetUs {
      mainPageMeetCaption
      mainPageMeetDescription
      mainPageMeetTitle
      mainPageMeetUrl {
        title
        url
      }
    }
    mainPageSiteSections {
      mainPageList {
        mainPageListSectionName
        mainPageListSectionUrl {
          title
          url
        }
        mainPageListIcon {
          altText
          srcSet
          sourceUrl
        }
      }
    }
  }
`;

module.exports.mainPage = mainPage;
