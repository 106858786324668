import React from 'react';
import classnames from 'classnames';
import { useWindowWidth } from '@react-hook/window-size/throttled';

import StaticImg from 'components/StaticImg';
import Caption from 'components/partials/Caption';
import ScrollPointer from 'components/partials/ScrollPointer';

import { titleStyle } from './styles.module.scss';

const image = {
  desktop: 'family/remember.jpg',
  mobile: 'family/remember-mob.jpg',
};

const Remember = ({ data }) => {
  const windowWidth = useWindowWidth({ initialWidth: 640 });

  return (
    <section className="mb-20 lg:mb-28">
      <div className="fluid-container">
        <div className="relative mx-auto 2xl:w-7/12">
          <div className={'-mx-5 mb-16 lg:h-auto sm:mx-0 text-center'}>
            <StaticImg
              path={windowWidth > 640 ? image.desktop : image.mobile}
              className="w-full lg:mx-0"
            />
            {windowWidth <= 640 ? (
              <div className="sticky bottom-5 px-5">
                <ScrollPointer
                  bgColor="bg-mine-shaft"
                  className="-mt-20 sm:mt-14 mx-auto"
                  anchor="/lung-cancer/family-and-caregivers#anchor-target"
                />
              </div>
            ) : null}
          </div>

          <div className="sm:flex sm:justify-between sm:items-start">
            <Caption bgColor="bg-sky" className="mb-14 sm:flex-4 sm:mb-0">
              {data.caption}
            </Caption>

            <h3
              className={classnames('oc-h3 sm:flex-1', titleStyle)}
              dangerouslySetInnerHTML={{ __html: data.text }}
            ></h3>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Remember;
